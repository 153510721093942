<script>

import {ColumnDirective, ColumnsDirective, GridComponent as EjsGrid} from "@syncfusion/ej2-vue-grids";
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import axios from "axios";
import swal from "sweetalert";

export default {
    name: 'AssignProxyServices',
    components: {
        EjsGrid,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
        'ejs-dropdownlist': DropDownListComponent,
    },
    data() {
        return {
            assigned_proxy_services: [],
            products: [],
            dropdownFields: {text: 'name', value: '_id'},
            proxy_providers: [],
            proxy_dropdownFields: {text: 'provider_name', value: '_id'},
            proxy_services: [],
            services_dropdownFields: {text: 'service_name', value: '_id'},
            selected_provider: '',
            selected_service: '',
            selected_product: '',
        };
    },
    methods: {
        initialize() {
            this.loadAssignedProxyServices();
            this.loadProducts();
            this.loadProxyProviders()
        },
        loadProducts: function () {
            const request = {'apikey': this.$root.apikey};
            axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params: request}).then(function (resp) {
                this.products = resp.data.data
            }.bind(this));
        },
        onProviderChange(e) {
            this.selected_provider = e.value;
            this.getServiceByProvider();
        },
        onServiceChange(e) {
            this.selected_service = e.value;
        },
        onProductChange(e) {
            this.selected_product = e.value;
        },
        getServiceByProvider() {
            axios.get(`${this.$root.serverUrl}/admin/proxy-services/provider/${this.selected_provider}`).then(
                (resp) => {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.proxy_services = resp && resp.data && resp.data.data ? resp.data.data : [];
                }
            );
        },
        loadProxyProviders() {
            axios.get(`${this.$root.serverUrl}/admin/proxy-providers`).then(
                function (resp) {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.proxy_providers = resp && resp.data && resp.data.data ? resp.data.data : [];
                }.bind(this)
            );
        },
        loadAssignedProxyServices() {
          axios.get(`${this.$root.serverUrl}/admin/assigned-proxy-services`).then(
              (resp) => {
                  console.log(resp.data.data)
                  this.$root.preloader = false;
                  this.assigned_proxy_services = resp && resp.data && resp.data.data ? resp.data.data : [];
              }
          );
        },
        onAssignProxyService() {
            const assignProxyService = {
                product: this.selected_product,
                provider: this.selected_provider,
                service: this.selected_service,
            }
            const request = {
                apiKey: this.$root.apiKey,
                args: assignProxyService
            }

            axios.post(`${this.$root.serverUrl}/admin/assigned-proxy-services`, request)
                .then((res) => {
                    if (res.data.error) {
                        swal("Error", res.data.error, "error");
                        return;
                    }
                    swal("Success", "Proxy service assigned successfully", "success");
                    this.show = false;
                })
                .catch(error => {
                    swal("Error", "An error occurred while assigning the proxy service", "error");
                    console.log(error);
                });
            this.initialize()
        }
    },
    mounted() {
        this.initialize()
    },

};
</script>

<template>
    <div class="container-fluid">
        <b-row class="m-2">
            <b-col cols="3">
                <ejs-dropdownlist
                    placeholder="Product"
                    :dataSource="products"
                    cssClass="e-outline"
                    floatLabelType="auto"
                    :fields="dropdownFields"
                    @change="onProductChange"
                ></ejs-dropdownlist>
            </b-col>
            <b-col cols="3">
                <ejs-dropdownlist placeholder="Provider" :dataSource="proxy_providers" cssClass="e-outline"
                                  floatLabelType="auto" :fields="proxy_dropdownFields"
                                  @change="onProviderChange"
                ></ejs-dropdownlist>
            </b-col>
            <b-col cols="3">
                <ejs-dropdownlist placeholder="Service" :dataSource="proxy_services" cssClass="e-outline"
                                  :fields="services_dropdownFields"
                                  @change="onServiceChange"
                                  floatLabelType="auto"></ejs-dropdownlist>
            </b-col>
            <b-col cols="3">
                <b-button variant="primary" @click="onAssignProxyService">
                    <i class="fa fa-plus mr-1"></i>
                    Assign Proxy Service
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <ejs-grid :dataSource="assigned_proxy_services">
                <e-columns>
                    <e-column field="product_info.Name" headerText="Product" width="100"></e-column>
                    <e-column field="provider_info.provider_name" headerText="Provider" width="100"></e-column>
<!--                    <e-column field="zone" headerText="Zone" width="100"></e-column>-->
                    <e-column field="service_info.service_name" headerText="Service" width="100"></e-column>
                    <e-column field="display_price" headerText="Rate" width="100"></e-column>
                    <e-column field="display_start_date" headerText="Start Date" width="100"></e-column>
                    <e-column field="display_end_date" headerText="End Date" width="100"></e-column>
                </e-columns>
            </ejs-grid>

        </b-row>
    </div>
</template>

<style scoped>

</style>
