<template>
<div class="">

                <div class="form-group">
                    <label for="recipient-name" class="col-form-label">Date Of Incident?</label>
                    <b-form-datepicker id="datepicker" v-model="value" class="mb-2"></b-form-datepicker>
                    <label for="recipient-name" class="col-form-label">Whats the reason for this reset?</label>
                    <b-form-textarea
                        id="textarea"
                        v-model="text"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6"
                    ></b-form-textarea>
                </div>
                <div class="form-group">
                    <div class="mt-4"><label><strong>You are about to reset counter please note:</strong></label></div>
                    <div>
                        <ul style="font-weight:normal">
                            <li>Be Sure you are really intending to reset the counter, as this is final.</li>
                            <li>The date of the incident is needed reset this counter.</li>
                            <li>A valid reason is needed to reset this counter.</li>
                            <li>Your name will be attached to the reset of this counter</li>
                        </ul>
                    </div>
                </div>
                <div class="form-group text-center">
                    <button type="button" class="btn btn-md btn-danger mr-2 text-center" @click="resetIncidents"><i class="fa fa-refresh"></i> Reset</button>
                </div>

</div>
</template>

<script>
// import incidents from "@/components/reports/incidentsReport";
import axios from "axios";
import swal from "sweetalert";

export default {
    props: {
        user: {
            type: Object,
            required: false
        }
    },
    components: {
        // incidents,

    },
    data: () => {
        return {
            value:'',
            text: '',
            reportData: [],
            errorCounter: null,
            counter:{counter:0},
        };
    },
    created() {
        //Register eventstream
        // if(!this.errorCounter) {
        //     this.errorCounter = new EventSource(`${this.$root.serverUrl}/static/incidentsCount`, {withCredentials: false});
        //
        //     this.errorCounter.onerror = (error) => {
        //         console.log(`incidentsCount.error`, error);
        //     }
        //     this.errorCounter.onopen = (args) => {
        //         console.log(`incidentsCount.open`, args);
        //     }
        //     this.errorCounter.onmessage = (event) => {
        //         //console.log(`eventWorkers.event`, event);
        //         try {
        //             const parsedData = JSON.parse(event.data);
        //             this.counter = Object.assign( parsedData);
        //             this.$forceUpdate();
        //         } catch (e) {
        //             console.log(`incidentsCount.catch: ${e.message}`);
        //         }
        //     };
        // }
    },
    computed: {
        getIncidents() {
            let data = this.counter.counter;
            return data;
        },
    },
    methods:{
        resetIncidents() {
            var request = {'apikey': this.$root.apikey};
            axios.post(`${this.$root.serverUrl}/admin/reports/incidentsUpdate`, {  counter: this.counter.counter,reason : this.text, date:this.value, user:this.user},{params:request}).then((resp) => {
                if(resp.data.error){
                    swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {

                    })
                }else{
                    this.$emit('fetch-data');
                    swal({ title: 'Success', text: 'Reset Done.', icon: 'success' }).then(() => {

                    })
                }

            }).catch(() => {
                swal("Oops!", "There was an issue Resting", "error");
            })
        },




    }
};
</script>

<style>
</style>
