<template>
    <div>
        <div class="modal fade" v-if="visible" :class="{ show: visible }" id="edit_pricing_contract_modal" role="dialog"
             aria-labelledby="modalLabel" :aria-hidden="!visible">

            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Edit Pricing Contract</h4>
                        <button
                            type="button"
                            class="close"
                            @click="close"
                        >
                            &times;
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="container-fluid">
                            <b-form>
                                <b-row class="mb-4 mt-4">
                                    <b-col cols="6">
                                        <ejs-dropdownlist placeholder="Provider" :dataSource="proxy_providers"
                                                          cssClass="e-outline"
                                                          floatLabelType="auto" :fields="proxy_dropdownFields"
                                                          @change="onProviderChange"
                                                          :value="formData.provider"
                                        ></ejs-dropdownlist>
                                    </b-col>
                                    <b-col cols="6">
                                        <ejs-dropdownlist id="service" placeholder="Service" floatLabelType="auto"
                                                          :dataSource="proxy_services" cssClass="e-outline"
                                                          :fields="services_dropdownFields"
                                                          :value="formData.service"
                                                          @change="onServiceChange"/>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">
                                        <b-form-group label="Price" label-for="price">
                                            <b-input-group>
                                                <b-input-group-prepend is-text>$</b-input-group-prepend>
                                                <b-form-input id="price" v-model="formData.price" type="number"
                                                              required/>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-group label="Date range" label-for="date_range">
                                            <ejs-daterangepicker id="date_range" cssClass="e-outline"
                                                                 :value="dateRange"
                                                                 @change="onDateRangeChange"></ejs-daterangepicker>
                                        </b-form-group>
                                    </b-col>

                                </b-row>

                            </b-form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <b-button variant="secondary" @click="close">Close</b-button>
                        <b-button variant="primary" @click="editForm">Edit</b-button>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop" @click="close" v-if="visible"></div>
        </div>

    </div>
</template>

<script>
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import {DateRangePickerComponent} from "@syncfusion/ej2-vue-calendars";
import axios from "axios";
import swal from "sweetalert";

export default {
    name: 'EditPricingContract',
    components: {
        'ejs-dropdownlist': DropDownListComponent,
        'ejs-daterangepicker': DateRangePickerComponent,
    },
    props: {
        pricing_contract: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            show: false,
            proxy_providers: [],
            proxy_dropdownFields: {text: 'provider_name', value: '_id'},
            proxy_services: [],
            services_dropdownFields: {text: 'service_name', value: '_id'},
            formData: {
                _id: '',
                provider: '',
                service: '',
                price: '',
                start_date: '',
                end_date: '',
            }
        };
    },
    computed: {
        dateRange() {
            return [this.formData.start_date, this.formData.end_date];
        }
    },
    watch: {
        pricing_contract: {
            handler: function (newVal) {
                const parsedPricingContract = typeof newVal === 'string' ? JSON.parse(newVal) : newVal;
                this.formData = {
                    _id: parsedPricingContract._id,
                    provider: parsedPricingContract.provider,
                    service: parsedPricingContract.service,
                    price: parsedPricingContract.price,
                    start_date: new Date(parsedPricingContract.start_date),
                    end_date: new Date(parsedPricingContract.end_date),
                };
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        onProviderChange(event) {
            this.formData.provider = event.value;
            this.getServiceByProvider();
        },
        onServiceChange(event) {
            this.formData.service = event.value;
        },

        getServiceByProvider() {
            axios.get(`${this.$root.serverUrl}/admin/proxy-services/provider/${this.formData.provider}`).then(
                (resp) => {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.proxy_services = resp && resp.data && resp.data.data ? resp.data.data : [];
                }
            );
        },
        onDateRangeChange(event) {
            const {startDate, endDate} = event;
            this.formData.start_date = new Date(startDate);
            this.formData.end_date = new Date(endDate);
        },
        loadProxyProviders() {
            axios.get(`${this.$root.serverUrl}/admin/proxy-providers`).then(
                function (resp) {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.proxy_providers = resp && resp.data && resp.data.data ? resp.data.data : [];
                }.bind(this)
            );
        },
        async editForm() {
            const request = {
                apiKey: this.$root.apiKey,
                args: this.formData
            }
            axios.patch(`${this.$root.serverUrl}/admin/pricing-contracts`, request).then(res => {
                    console.log(res.data)
                    this.$root.preloader = false;
                if (res.data.error) {
                    swal('error', res.data.error, 'error');
                } else {
                    swal('Success', 'Pricing contract edited successfully', 'success');

                }
                    this.show = false;
                    close();
                }
            )
            this.$emit('close');
        }
    },
    mounted() {
        this.loadProxyProviders();
    },
};
</script>

<style>

.modal .fade {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1150 !important;
}

.modal-dialog {
    min-width: 600px;
    max-width: 1200px;
    margin: 30px auto;
}

.modal-content {
    z-index: 1150 !important;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;

}

.modal-header {
    background: #0d0d48;
    color: #ffffff;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 1px solid #ddd;
}

.modal-title {
    margin: 0;
}

.close {
    color: #ffff;
    font-size: 1.8rem;
    font-weight: bold;
    border: none;
    background: transparent;
}

.close:hover {
    color: #ffff;
    text-decoration: none;
    cursor: pointer;
}

.modal-body {
    padding: 15px;
    min-height: 50vh;
    max-height: 75vh;
    overflow: auto;
}

.show {
    display: block;
}

.modal-backdrop {
    z-index: 940 !important;
}

</style>
