<script>
import Modal from "../utils/modal.vue";
import swal from "sweetalert2";
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import axios from "axios";

export default {
    name: 'NewProvider',
    components: {Modal,
    'ejs-dropdownlist': DropDownListComponent,
    },
    data() {
        return {
            provider_name: '',
            address: '',
            phone: '',
            key_contacts: [],
            new_key_contact: {
                name: '',
                email_id: '',
                phone: ''
            },
            support_contacts: [],
            new_support_contact: {
                name: '',
                email_id: '',
                phone: ''
            },
            api_endpoint: '',
            guid: '',
            selected_auth_method: '',
            auth_methods: ['SFA', 'MFA', '2FA'],
            services_provided: '',
            visible: false,
            fields: [
                {key: 'name', label: 'Name'},
                {key: 'email_id', label: 'Email ID'},
                {key: 'phone', label: 'Phone'},
                {key: 'actions', label: 'Actions'}
            ]
        };
    },
    methods: {
        saveProvider() {
            const new_provider = {
                provider_name: this.provider_name,
                address: this.address,
                phone: this.phone,
                key_contacts: this.key_contacts,
                support_contacts: this.support_contacts,
                api_endpoint: this.api_endpoint,
                guid: this.guid,
                auth_method: this.selected_auth_method,
                services_provided: this.services_provided
            };
            const request = {
                apiKey : this.$root.apiKey,
                args: new_provider
            }
            axios.post(`${this.$root.serverUrl}/admin/proxy-providers`, request)
                .then((resp) => {
                    console.log(resp)
                    swal.fire({
                        title: "Success",
                        text: "Provider saved",
                        icon: "success",
                    });
                });
            this.visible = false;
            this.$emit('close');
        },
        addProvider() {
            this.visible = true;
        },
        addKeyContract() {
            this.key_contacts.push(this.new_key_contact);
            this.new_key_contact = {
                name: '',
                email_id: '',
                phone: '',
            };
        },
        deleteKeyContract(item) {
            swal.fire({
                title: "Remove Key Contact",
                text: "Are you sure?",
                icon: "question",
            }).then((willDelete) => {
                if (willDelete) {
                    this.key_contacts = this.key_contacts.filter(contact => contact !== item);
                    swal.fire({
                        title: "Success",
                        text: "Key contact removed",
                        icon: "success",
                    });
                }
            });
        },
        addSupportContract () {
            this.support_contacts.push(this.new_support_contact);
            this.new_support_contact = {
                name: '',
                email_id: '',
                phone: '',
            };
        },
        deleteSupportContract(item) {
            swal.fire({
                title: "Remove Support Contact",
                text: "Are you sure?",
                icon: "question",
            }).then((willDelete) => {
                if (willDelete) {
                    this.support_contacts = this.support_contacts.filter(contact => contact !== item);
                    swal.fire({
                        title: "Success",
                        text: "Support contact removed",
                        icon: "success",
                    });
                }
            });
        },
    },
    mounted() {
    },
};

</script>

<template>
    <div>
        <b-button
            variant="primary"
            @click="addProvider"
        >
            <i class="fa fa-plus mr-1"></i> Add
        </b-button>
        <Modal title="New Provider" id="new_provider_modal" :visible.sync="visible">
            <b-row>
                <b-col cols="4">
                    <b-form-group
                        label="Provider Name"
                        label-for="provider_name"
                    >
                        <b-form-input
                            id="provider_name"
                            v-model="provider_name"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="Address"
                        label-for="address"
                    >
                        <b-form-input
                            id="address"
                            v-model="address"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group label="Phone Number" label-for="phone_number">
                        <b-form-input
                            id="phone_number"
                            v-model="phone"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <h6><strong>Key Contacts</strong></h6>
            <b-row>
                <b-col cols="4">
                    <b-form-group
                        label="Name"
                        label-for="key_contact_name"
                    >
                        <b-form-input
                            id="key_contact_name"
                            v-model="new_key_contact.name"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="Email"
                        label-for="key_contact_email"
                    >
                        <b-form-input
                            id="key_contact_email"
                            v-model="new_key_contact.email_id"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label="Phone"
                        label-for="key_contact_phone"
                    >
                        <b-form-input
                            id="key_contact_phone"
                            v-model="new_key_contact.phone"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="1" class="d-flex justify-content-center align-items-center">
                    <b-button
                        variant="primary"
                        @click="addKeyContract"
                    >
                        <i class="fa fa-plus"></i>
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" v-if="key_contacts.length > 0">
                    <b-table :items="key_contacts" :fields="fields" striped>
                        <template #cell(actions)="data">
                            <b-button size="sm" variant="danger" @click="deleteKeyContract(data.item)">
                                <i class="fa fa-trash"></i>
                            </b-button>
                        </template>

                    </b-table>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center align-items-center" v-else>
                    <strong>No key contacts added</strong>
                </b-col>
            </b-row>

            <h6><strong>Support Contracts</strong></h6>
            <b-row>
                <b-col cols="4">
                    <b-form-group
                        label="Name"
                        label-for="support_contact_name"
                    >
                        <b-form-input
                            id="support_contact_name"
                            v-model="new_support_contact.name"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="Email"
                        label-for="support_contact_email"
                    >
                        <b-form-input
                            id="support_contact_email"
                            v-model="new_support_contact.email_id"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label="Phone"
                        label-for="support_contact_phone"
                    >
                        <b-form-input
                            id="support_contact_phone"
                            v-model="new_support_contact.phone"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="1" class="d-flex justify-content-center align-items-center">
                    <b-button
                        variant="primary"
                        @click="addSupportContract"
                    >
                        <i class="fa fa-plus"></i>
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" v-if="support_contacts.length > 0">
                    <b-table :items="support_contacts" :fields="fields" striped>
                        <template #cell(actions)="data">
                            <b-button size="sm" variant="danger" @click="deleteSupportContract(data.item)">
                                <i class="fa fa-trash"></i>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center align-items-center" v-else>
                    <strong>No support contacts added</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <b-form-group
                        label="API Endpoint"
                        label-for="api_endpoint"
                    >
                        <b-form-input
                            id="api_endpoint"
                            v-model="api_endpoint"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="GUID"
                        label-for="guid"
                    >
                        <b-form-input
                            id="guid"
                            v-model="guid"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label="Auth Method"
                        label-for="auth_method"
                    >
                        <ejs-dropdownlist
                            id="auth_method"
                            :dataSource="auth_methods"
                            cssClass="e-outline"
                            v-model="selected_auth_method"
                        ></ejs-dropdownlist>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:footer>
                <b-button variant="secondary" @click="visible = false">Close</b-button>
                <b-button variant="primary" @click="saveProvider">Save</b-button>
            </template>
        </Modal>
    </div>
</template>

<style scoped>

</style>
