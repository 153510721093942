
<template>
    <div class="col-12">
        <div class="mt-4">
        <h4 class="text-center text-bolder">Inbox Enhanced</h4>
        </div>
        <div class="container-fluid mb-5 mt-4">
            <div class="d-flex mt-4 mb-3 justify-content-between">
                <h5> Application Level setting</h5>
            </div>
    <div class=" card border-dark p-5 ">

        <hr class="text-success">
        <div class="col-12 card p-4">
            <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label>Enter a list to <span class="text-danger">Avoid</span> replying to</label>
                        <b-form-tags input-id="tags-basic" v-model="item.inboxEnhanced.exclusionList" placeholder="Enter here"></b-form-tags>
                        <div class="form-text text-muted">Enter short name ex. ebay</div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label>Enter a list to <span class="text-danger">Delete</span> emails from</label>
                        <b-form-tags input-id="tags-basic" v-model="item.inboxEnhanced.deleteList" placeholder="Enter here"></b-form-tags>
                        <div class="form-text text-muted">Enter short name ex. ebay</div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label>Enter a list to <span class="text-danger">Forward</span> emails from</label>
                        <b-form-tags input-id="tags-basic" v-model="item.inboxEnhanced.forwardList" placeholder="Enter here"></b-form-tags>
                        <div class="form-text text-muted">Enter short name ex. ebay</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 text-center">
                    <button class="btn btn-md btn-success text-center" @click.p.prevent ="acceptChange"> Save Changes</button>
                </div>

            </div>

        </div>

        <div class="col-12 card p-4 mt-4">
            <h4 class="text-center">Change History</h4>
            <hr class="text-danger">
            <div class="row">

        <b-table :items="item.inboxEnhancedChangeHistory" :fields="['timestamp', 'user', 'field', 'oldValue', 'newValue']">
            <template #cell(timestamp)="data">
                <span >{{  convert(data.item.timestamp) }}</span>
            </template>
            <template #cell(oldValue)="data">
                <span class="text-danger">{{ data.item.oldValue.join(', ') }}</span>
            </template>
            <template #cell(newValue)="data">
                <span class="text-success">{{ data.item.newValue.join(', ')  }}</span>
            </template>
        </b-table>
            </div>
        </div>


        <div class="col-12 card p-4 mt-4">
            <h4 class="text-center">Proxy Configuration</h4>
            <hr class="text-danger">

            <div class="text-right pb-3">
                <button class="btn btn-sm btn-success text-center" @click.p.prevent ="createProxy()"> Create Proxy</button>
            </div>
            <b-modal id="modal-inbox"  size="lg">
            <inboxEnhancedProxies :item="item" :proxy="proxies"/>

                <template #modal-footer="{cancel, submit }">
                    <b-button size="sm" variant="danger" @click="cancelButton">
                        Cancel
                    </b-button>
                    <!-- Button with custom close trigger value -->
                    <b-button size="sm" variant="outline-success" @click="add" v-if="editer == false">
                        Save Changes
                    </b-button>

                    <b-button size="sm" variant="outline-success" @click="editAdd" v-if="editer == true">
                        Save Edit
                    </b-button>
                </template>
            </b-modal>
            <div class="row">

                <b-table :items="item.inboxEnhancedProxies" :fields="['ipAddress', 'port', 'protocol', 'provider', 'username','country','city','state','status','actions']" >
                    <template #cell(actions)="data">
                        <b-button size="sm" variant="info" class="mr-2" @click="edit(data.index)"><i class="fa fa-pencil" /></b-button>
<!--                        <b-button size="sm" variant="danger" @click="removeForwarding(data.index)"><i class="fa fa-trash" /></b-button>-->
                        <!--                                removeForwarding(index)-->
                    </template>
                    <template #cell(status)="data">
                        <span :class="data.item.status == 'active'? 'text-success':'text-danger'">{{ data.item.status }}</span>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from "moment-timezone";
import swal from "sweetalert";
import inboxEnhancedProxies from "@/components/settings/inboxEnhancedProxies"

export default {
    name: "inboxEnhanced",
    props: ["user","item"],
    components:{inboxEnhancedProxies},



    data() {
        return {
            editer:false,
            proxies:{
                ipAddress:'',
                port:'',
                protocol:'',
                provider:'',
                username:'',
                password:'',
                country:'',
                state:'',
                city:'',
                status:'disabled'
            }
        };
    },
    created() {
        // this.fetchSettings();
        // if (!this.item.inboxEnhanced) this.item.inboxEnhanced = {exclusionList: [], deleteList: [], forwardList: []}
        // if (!this.item.inboxOriginalItem) this.item.inboxOriginalItem = {exclusionList: [], deleteList: [],forwardList: []}
        // if (!this.item.inboxEnhancedChangeHistory) this.item.inboxEnhancedChangeHistory = []

    },
    methods: {
        cancelButton(){
            this.$bvModal.hide('modal-inbox');
            this.proxies = {
                ipAddress:'',
                    port:'',
                    protocol:'',
                    provider:'',
                    username:'',
                    password:'',
                    country:'',
                    state:'',
                    city:'',
                    status:''
            }
        },
        removeForwarding(index) {

            this.item.inboxEnhancedProxies.splice(index, 1);
            // this.formData.forwardings.splice(index, 1)
            this.$forceUpdate()
        },
        edit(index){
            this.editer = true
            this.$forceUpdate()
             this.proxies = this.item.inboxEnhancedProxies[index]
            this.$bvModal.show('modal-inbox');
            // this.editer = false
        },
        add(){
            this.item.inboxEnhancedProxies.push(this.proxies)
            this.proxies = {
                ipAddress:'',
                port:'',
                protocol:'',
                provider:'',
                username:'',
                password:'',
                country:'',
                state:'',
                city:'',
                status:''
            }
            this.saveChanges()

        },
        editAdd(){
            this.saveChanges()
            this.proxies = {
                ipAddress:'',
                port:'',
                protocol:'',
                provider:'',
                username:'',
                password:'',
                country:'',
                state:'',
                city:'',
                status:''
            }
       this.editer = false

        },
        createProxy(){
            this.$bvModal.show('modal-inbox');
        },
        convert(time) {
            return moment.tz(time, this.user.timezone).format('MM-D-YYYY, h:mm:ss a')
        },

        acceptChange() {

            const changes = [];

            if (JSON.stringify(this.item.inboxEnhanced.exclusionList) !== JSON.stringify(this.item.inboxOriginalItem.exclusionList)) {
                changes.push({
                    field: 'exclusionList',
                    user:this.user.name,
                    timestamp: new Date().toISOString(),
                    oldValue: this.item.inboxOriginalItem.exclusionList,
                    newValue: this.item.inboxEnhanced.exclusionList
                });
            }

            if (JSON.stringify(this.item.inboxEnhanced.deleteList) !== JSON.stringify(this.item.inboxOriginalItem.deleteList)) {
                changes.push({
                    field: 'deleteList',
                    user:this.user.name,
                    timestamp: new Date().toISOString(),
                    oldValue: this.item.inboxOriginalItem.deleteList,
                    newValue: this.item.inboxEnhanced.deleteList
                });
            }
            if (JSON.stringify(this.item.inboxEnhanced.forwardList) !== JSON.stringify(this.item.inboxOriginalItem.forwardList)) {
                changes.push({
                    field: 'forwardList',
                    user:this.user.name,
                    timestamp: new Date().toISOString(),
                    oldValue: this.item.inboxOriginalItem.forwardList,
                    newValue: this.item.inboxEnhanced.forwardList
                });
            }


            if (changes.length > 0) {

                this.item.inboxEnhancedChangeHistory.push(...changes)
                this.saveChanges()

            }
        },
        saveChanges(){
            //Handle saving the changes
            // this.item.exclusions =  this.item.exclusions.join(',')
            // this.item.sideload_patterns =  this.item.sideload_patterns.join(',')
            var request = { apikey: this.$root.apikey, args: this.item };
            // this.$root.preloader = true;

            axios({
                url: `${this.$root.serverUrl}/admin/settings`,
                data: request,
                responseType: "json",
                method: "POST",
            })
                .then(
                    function (resp) {
                        //Process the results
                        if (resp && resp.data) {
                            //Saved successfully, now update the parent

                            swal({
                                title: "Notice",
                                text: "Your changes were saved successfully",
                                icon: "success",
                            }).then(
                                function () {
                                    this.item.inboxEnhanced = resp.data.data.update.inboxEnhanced
                                    this.item.inboxOriginalItem = resp.data.data.update.inboxOriginalItem
                                    this.item.inboxEnhancedChangeHistory = resp.data.data.update.inboxEnhancedChangeHistory
                                    this.item.inboxEnhancedProxies = []
                                    this.item.inboxEnhancedProxies = resp.data.data.update.inboxEnhancedProxies
                                    this.$forceUpdate();
                                }.bind(this)
                            );
                        } else {
                            //Handle errors
                            console.log('r')
                        }
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        this.$root.preloader = false;
                        console.log(err)
                        // this.errormsg = err.message || "An unexpected error occured";
                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                    }.bind(this)
                );
        },
        cancelChange(field) {
            this.newValues[field] = this.settings.find(s => s.field === field).value;
        },
        formatKey(key) {
            return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
        }
    }
};
</script>

<style scoped>
.container {
    margin-top: 20px;
}
</style>
