<template>
    <div class="col-12">
        <div class="container-fluid mb-5 mt-4">
            <div class="d-flex mt-4 mb-3 justify-content-between">
                <p>Manage Chat API Keys</p>
                <span>
                    <button class="btn btn-sm btn-info" @click="create = true;">
                        <i class="fa fa-key" ></i> New Key
                    </button>
                </span>
            </div>

            <div v-if="create" class="mb-5">
                <div class="col-12">
                    <div class="row mt-4">
                        <div class="col-3 pl-4 pr-4">
                            <label>CHAT API KEY<strong class="text-danger">*</strong></label>
                            <input
                                type="text"
                                v-model="key.api_key"
                                placeholder="sk-************"
                                class="form-control required"
                                required
                            />
                            <div class="form-text text-muted">Must be a valid Chat GPT API KEY.</div>
                        </div>
                        <div v-if="!models.length" class="col-3 pl-4 pr-4 pt-4 mt-1">
                            <b-button
                                size="md"
                                variant="primary"
                                @click="loadModels"
                                v-text="'Load Models'"
                            />
                        </div>
                        <div v-if="models.length" class="col-3 pl-4 pr-4">
                            <label>Model <strong class="text-danger">*</strong></label>
                            <select
                                v-model="key.model"
                                class="form-control"
                            >
                                <option
                                    v-for="model in models"
                                    :key="model.id"
                                    :value="model.id"
                                >
                                    {{ model.id }}
                                </option>
                            </select>
                            <div class="form-text text-muted">Select CHAT GPT model.</div>
                        </div>
                        <div v-if="models.length" class="col-3 pl-4 pr-4">
                            <label>Description<strong class="text-danger">*</strong></label>
                            <input
                                type="text"
                                v-model="key.description"
                                class="form-control required"
                                required
                            />
                            <div class="form-text text-muted">Description of model usage.</div>
                        </div>
                        <div v-if="models.length" class="col-3 pl-4 pr-4 pt-4 mt-1">
                            <b-button
                                size="md"
                                variant="primary"
                                @click="generate"
                                v-text="'Generate Key'"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ejs-grid ref="grid" :dataSource="data" :query="query" :allowPaging="true" :allowSorting="true" :pageSettings="pageSettings">
                <e-columns>
                    <e-column field="date" headerText="Creation Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'medium'}"></e-column>
                    <e-column field="model" headerText="Model" text-Align="Left" ></e-column>
                    <e-column field="description" headerText="Description" text-Align="Left" ></e-column>
                    <e-column field="key" headerText="Api Key" text-Align="Left"></e-column>
                    <e-column :template="actionTemplate"></e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</template>

<script>
import {DataManager, Query, WebApiAdaptor} from "@syncfusion/ej2-data";
import Vue from "vue";
import axios from "axios";
import cuid from "cuid";
import swal from "sweetalert";

Vue.prototype.$eventHub = new Vue();

export default {
    name: "chat",
    props: ["item"],
    beforeDestroy() {
        this.$eventHub.$off('delete');
    },
    created() {
        this.$eventHub.$on('delete', ()=>{
            this.data = new DataManager({
                url: `${this.$root.serverUrl}/admin/chat/list/keys`,
                adaptor: new WebApiAdaptor(),
                crossDomain: true,
                headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            })
        });
    },
    computed: {
        query() {
            let filters = Object.assign({}, this.temp);
            return new Query().addParams('filters', JSON.stringify(filters));
        }
    },
    data: function () {
        return {
            key: {
                api_key: "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"
            },
            create: false,
            models: [],
            pageSettings: { pageSize: 50, pageSizes: [50, 100, 500] },
            data: new DataManager({
                url: `${this.$root.serverUrl}/admin/chat/list/keys`,
                adaptor: new WebApiAdaptor(),
                crossDomain: true,
                headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            }),
            actionTemplate: function () {
                return {
                    template: Vue.component('tosTemplate', {
                            template: `<button @click="revoke(data._id)" class='btn btn-primary btn-sm'>
                                          <span class="fa fa-trash" title="Revoke"></span>
                                        </button>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            methods: {
                                revoke: function(key){
                                    swal({title:"Remove", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                                        if (!val) return;

                                        axios.post(`${this.$root.serverUrl}/admin/chat/revoke/key`, {key: key}).then((resp) => {
                                            if (resp && resp.data && resp.data.error) {
                                                swal({ title: "Error", text: "There was an error revoking key", icon: "error" });
                                            }

                                            this.$eventHub.$emit('delete');
                                        }).catch((err) => {
                                            console.log(err)
                                            swal({ title: "Error", text: "There was an error revoking key", icon: "error" });
                                        });
                                    });
                                },
                            }
                        }
                    )
                }
            }
        };
    },
    methods: {
        generate() {
            let api_key = cuid();
            let access = {
                key: api_key,
                model: this.key.model,
                chat_key: this.key.api_key,
                description: this.key.description
            }

            axios.post(`${this.$root.serverUrl}/admin/chat/create/key`, access)
                .then((resp) => {
                    if (resp && resp.data && !resp.data.error) {
                        swal({
                            title: "Success",
                            text: "Access Key Created Successfully.",
                            icon: "success",
                        });
                        this.create = false;
                        this.data = new DataManager({
                            url: `${this.$root.serverUrl}/admin/chat/list/keys`,
                            adaptor: new WebApiAdaptor(),
                            crossDomain: true,
                            headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                        })
                    }
                    if (resp && resp.data && resp.data.error) {
                        swal({ title: "Error", text: "There was an error processing your request.", icon: "error" });
                    }
                }).catch((err) => {
                    console.log(err)
                    swal({ title: "Error", text: "There was an error processing your request.", icon: "error" });
                })
        },
        loadModels: function(){
            return axios.get(`${this.$root.serverUrl}/admin/chat/list/models`, {params: {key: this.key.api_key}}).then((resp) => {
                this.models = (resp.data && resp.data.data)? resp.data.data : [];

                if (resp && resp.data && resp.data.error) {
                    swal({ title: "Error", text: "There was an error loading models.", icon: "error" });
                }
            }).catch((err) => {
                console.log(err)
                swal({ title: "Error", text: "There was an error loading models.", icon: "error" });
            });
        },
    }
};
</script>

<style>
</style>
