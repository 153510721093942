<script>
import PricingContracts from "./pircing_contracts/pricing_contracts.vue";
import ManageProxyServices from "./manage-proxy-services/manage_proxy_services.vue";
import AssignProxyServices from "./assign-proxy-services/assign_proxy_services.vue";
import Providers from "./providers/providers.vue";
import ArchivedContracts from "./archived_contracts/archived_contracts.vue";

export default {
    name: 'ProxyProviders',
    components: {ArchivedContracts, Providers, AssignProxyServices, ManageProxyServices, PricingContracts},
    data() {
        return {};
    },
    methods: {
        onTabChange(newTabIndex) {
            switch (newTabIndex) {
                case 0:
                    this.$refs.providersComponent.initialize();
                    break;
                case 1:
                    this.$refs.pricingContractsComponent.initialize();
                    break;
                case 2:
                    this.$refs.assignProxyServicesComponent.initialize();
                    break;
                case 3:
                    this.$refs.manageProxyServicesComponent.initialize();
                    break;
                case 4:
                    this.$refs.archivedContractsComponent.initialize();
                    break;
            }
        },

    },
    mounted() {
    },
};
</script>

<template>
    <div class="col-12">
        <div class="container-fluid mb-5 mt-4">
            <div class="d-flex mt-4 mb-3 justify-content-between">
                <h3>Proxy Provider Configuration</h3>
            </div> <b-card no-body>
            <b-tabs fill card @input="onTabChange">
                <b-tab title="Providers" active>
                    <providers ref="providersComponent" />
                </b-tab>
                <b-tab title="Pricing Contracts">
                    <pricing-contracts ref="pricingContractsComponent" />
                </b-tab>
                <b-tab title="Assign Proxy Services">
                    <assign-proxy-services ref="assignProxyServicesComponent" />
                </b-tab>
                <b-tab title="Manage Proxy Services">
                    <manage-proxy-services ref="manageProxyServicesComponent" />
                </b-tab>

                <b-tab title="Archived Contracts">
                    <archived-contracts ref="archivedContractsComponent" />
                </b-tab>
            </b-tabs>
        </b-card>
        </div>
    </div>
</template>

<style scoped>

</style>
