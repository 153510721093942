<template>
    <div class="col-12">
        <div class="row mt-5">
            <h4 class="col-12">Email Verifier Settings</h4>
        </div>
        <div class="card p-3 mb-3">
            <div class="row">
                <div class="col-sm-4 col-md-3 col-lg-3">
                    <b-form-group>
                        <label>Upload Email Master List <strong class="text-danger">*</strong></label>
                        <b-input-group>
                            <b-form-input v-model="item.ev.file.name" :disabled="busy"></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    ref="button"
                                    :disabled="busy"
                                    variant="dark"
                                    v-b-modal.modalPopover
                                >
                                    Upload
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <div class="form-text text-muted">Allows upload of an email list</div>
                    </b-form-group>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label>Maintenance Interval</label>
                        <input
                            type="number"
                            v-model="item.ev.maintenance_interval"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Maximum number of days campaigns are stored for</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label>Audience Percentage</label>
                        <input
                            type="number"
                            v-model="item.ev.audience_percentage"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Percentage of audience to calculate max clicks</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label>Max Radius</label>
                        <input
                            type="number"
                            v-model="item.ev.max_radius"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Maximum radius for proxy distance</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label>Pull Frequency</label>
                        <select v-model="item.ev.frequency" class="form-control text-capitalize required" required>
                            <option v-for="(frequency, index) in frequencies" :key="index" :value="frequency">{{ frequency }}</option>
                        </select>
                        <div class="form-text text-muted">Controls how often we pull updates from OMS</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label>OMS Username</label>
                        <input
                            type="string"
                            v-model="item.ev.oms_username"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Username for SI OMS Portal</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label>OMS Password</label>
                        <input
                            type="string"
                            v-model="item.ev.oms_password"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Password for SI OMS Portal</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                        <label>Campaign Duration (days)</label>
                        <input
                            type="number"
                            v-model="item.ev.duration"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Controls how many days the imported campaigns should run for.</div>
                    </div>
                </div>

            </div>
            <h5 class="mt-4">Queue Settings</h5>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <div class="form-group">
                        <label>Max Unsubscribe</label>
                        <input
                            type="number"
                            v-model="item.ev.max_unsubscribe"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Maximum % of clicks to click the unsubscribed link</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <div class="form-group">
                        <label>Nationwide DMA Min Count</label>
                        <input
                            type="number"
                            v-model="item.ev.dma_min"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Minimum dma to consider a campaign as nationwide</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <div class="form-group">
                        <label>Nationwide Geo Min Count</label>
                        <input
                            type="number"
                            v-model="item.ev.geo_min"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Minimum geo to consider a campaign as nationwide</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <div class="form-group">
                        <label>Nationwide Proxy Effort</label>
                        <input
                            type="number"
                            v-model="item.ev.max_nationwide_check"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Maximum effort to get a nationwide proxy for a campaign</div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-3">
                    <div class="form-group">
                        <label>Upper Proxy Distance</label>
                        <input
                            type="number"
                            v-model="item.ev.radiusUpperLimit"
                            class="form-control"
                            required
                        />
                        <div class="form-text text-muted">Upper limit to search for proxies in miles if no proxies avaliable in current radius</div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="modalPopover" hide-header hide-footer ref="modalPopover">
            <b-container>
                <h6 class="text-center p-2">Upload Email File</h6>
                <p>Select and Upload email file:</p>
                <div id="dropArea" class="pb-2">
                    <input
                        type="file"
                        accept=".zip"
                        ref="uploadObj"
                        @change="fileupload"
                    />
                </div>

                <div class="progress" style="height: 8px;">
                    <div class="progress-bar bg-success" role="progressbar" :style="`width: ${loaded}%`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <b-alert
                    :show="dismissCountDown"
                    dismissible
                    :variant="alert.variant"
                    @dismissed="dismissCountDown = 0"
                    @dismiss-count-down="countDownChanged"
                >
                    <p class="m-0" v-html="alert.message" />
                </b-alert>
                <b-button
                    class="text-danger float-right mt-3"
                    @click="hideUploadModal"
                    variant="outline-light">Done</b-button
                >
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
    name: "ev",
    props: {
        item: {
            type: Object,
            default: ()=>{
                return { ev: { file: {} } }
            }
        },
        save:{
            type: Object,
            default:()=>{return { }}
        }
    },
    data: () => {
        return {
            busy: null,
            loaded: 0,
            dismissCountDown: 0,
            alert: {
                variant: "",
                message: "",
            },
            frequencies: ["hourly", "daily", "monthly"],
        };
    },
    created() {
        if (!this.item.ev) this.item.ev = { file: {} }
        if (!this.item.ev.file) this.item.ev.file = {}
    },
    mounted() {},
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        hideUploadModal() {
            this.loaded = 0;
            this.$bvModal.hide("modalPopover");
        },
        fileupload() {
            let file = this.$refs.uploadObj.files[0];

            const request = new FormData();
            request.append("file", file);

            axios.post(`${this.$root.serverUrl}/admin/ev/upload/stream`, request, {
                onUploadProgress: progressEvent => this.loaded = (progressEvent.loaded/progressEvent.total) * 100,
                headers: {contentType: 'multipart/form-data'}
            }).then(() => {
                swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(() => {
                    this.fileUpload = false;
                });
            }).catch((err) => {
                console.log(err)
                swal({title:"Error", text: 'Communication Error', icon:"error"});
                this.$root.preloader = false;
            });

        },
    },
};
</script>

<style>
</style>
