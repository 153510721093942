<script>
import {ColumnDirective, ColumnsDirective, GridComponent as EjsGrid} from "@syncfusion/ej2-vue-grids";
import Vue from "vue";
import swal from "sweetalert";
import axios from "axios";
import NewProvider from "./new_provider.vue";
import ViewProvider from "@/components/settings/proxy_providers/providers/view_provider.vue";
import EditProvider from "@/components/settings/proxy_providers/providers/edit_provider.vue";

let Bus = new Vue();
export default {
    name: 'Providers',
    components: {
        EditProvider,
        ViewProvider,
        NewProvider,
        EjsGrid,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
    },

    data() {
        return {
            viewDetail: false,
            viewEdit: false,
            editProvider: null,
            detail: null,
            data: []
        };
    },
    methods: {
        initialize() {
            this.getProviders();
        },
        closeView() {
            this.detail = null;
            this.viewDetail = false;
        },
        closeEdit() {
            this.editProvider = null;
            this.viewEdit = false;
            this.getProviders();
        },

        actionTemplate: function () {
            return {
                template: Vue.component("actionTemplate", {
                    template: `
                        <div class="d-flex" style="gap: 5px;">
                            <button class="btn btn-sm btn-primary" @click="details"><i class="fa fa-eye"
                                                                                       title="View"></i></button>
                            <button class="btn btn-sm btn-primary" @click="edit"><i class="fa fa-pencil"
                                                                                    title="edit"></i></button>
                            <button class="btn btn-sm btn-danger" @click="remove"><i class="fa fa-trash"
                                                                                     title="Delete"></i></button>
                        </div>
                    `,
                    components: {
                        ViewProvider,
                    },
                    methods: {
                        remove: function () {
                            swal({
                                title: "Remove",
                                text: "Are you sure?",
                                icon: "info",
                                buttons: ["No", "Yes"],
                            }).then((val) => {
                                if (!val) return;
                                const request = {
                                    apikey: this.$root.apikey,
                                    id: this.data._id,
                                };
                                axios({
                                    url: `${this.$root.serverUrl}/admin/social/${this.data._id}`,
                                    data: request,
                                    responseType: "json",
                                    method: "DELETE",
                                })
                                    .then((resp) => {
                                        this.$root.preloader = false;
                                        if (resp.data && !resp.data.error) {
                                            swal({
                                                title: "Removed",
                                                text: "Your request was completed successfully",
                                                icon: "success",
                                            }).then(() => {
                                                Bus.$emit("delete");
                                            });
                                        } else {
                                            swal({
                                                title: "Remove",
                                                text: resp.data.error.message,
                                                icon: "error",
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        this.$root.preloader = false;
                                        this.errormsg = err.message || "An unexpected error occurred";
                                        swal({
                                            title: "Error",
                                            text: this.errormsg,
                                            icon: "error",
                                        });
                                    });
                            });
                        },
                        details: function () {
                            Bus.$emit("view", this.data);
                        },
                        edit: function () {
                            Bus.$emit("edit", JSON.stringify(this.data));
                        },
                    },
                }),
            };
        },
        async getProviders() {
            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/proxy-providers`);
                this.data = response.data.data;
                console.log(this.data);
            } catch (error) {
                console.log(error);
            }
        },
    },
    mounted() {
        this.initialize();
    },
    created() {
        Bus.$on('delete', () => {
            this.data = this.data.filter((item) => item._id !== this.detail._id);
        });
        Bus.$on('view', (data) => {
            console.log(data, 'view');
            this.detail = data;
            this.viewDetail = true;
        });
        Bus.$on('edit', (data) => {
            console.log(data, 'edit');
            this.editProvider = data;
            this.viewEdit = true;
        });
    },
    destroyed() {
        Bus.$off('delete');
        Bus.$off('view');
        Bus.$off('edit');
    }

};

</script>
<template>
    <div class="container-fluid">
        <b-row>
            <b-col cols="12" class="d-flex justify-content-end mb-1">
                <new-provider @close="getProviders"/>
            </b-col>
        </b-row>
        <b-row>
            <ejs-grid :dataSource="data">
                <e-columns>
                    <e-column field="provider_name" headerText="Provider Name" width="100"></e-column>
                    <e-column field="address" headerText="Address" width="100"></e-column>
                    <e-column field="display_key_contact" headerText="Key Contact" width="200"></e-column>
                    <e-column field="display_support_contact" headerText="Support Contact" width="200"></e-column>
                    <e-column field="phone" headerText="Phone" width="150"></e-column>
                    <e-column field="display_services" headerText="Services Provided" width="100"></e-column>
                    <e-column headerText="Actions" width="80" :template="actionTemplate"></e-column>
                </e-columns>
            </ejs-grid>
        </b-row>

        <edit-provider v-if="editProvider" :provider="editProvider" :visible="viewEdit" @close="closeEdit"/>
        <view-provider v-if="detail" :provider="detail" :visible="viewDetail" @close="closeView"/>
    </div>
</template>

<style>

</style>
