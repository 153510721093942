<script>
import Modal from "@/components/scraper/linkedin/results/utils/modal.vue";
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import axios from "axios";
import swal from "sweetalert";

export default {
    name: 'NewProxyService',
    components: {
        Modal,
        'ejs-dropdownlist': DropDownListComponent,
    },
    props: {
        proxy_providers: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            show: false,
            providers_dropdownFields: {text: 'provider_name', value: '_id'},
            auth_methods: ['0Auth2', 'Basic Auth'],
            formData: {
                service_name: '',
                provider: '',
                api_endpoint: '',
                guid: '',
                auth_method: '',
            }
        };
    },
    methods: {
        saveForm() {
            console.log(this.formData);
            const request = {
                apiKey: this.$root.apiKey,
                args: this.formData
            }
            axios.post(`${this.$root.serverUrl}/admin/proxy-services`, request)
                .then(() => {
                    swal("Success", "Proxy service created successfully", "success");
                    this.show = false;
                })
                .catch(error => {
                    swal("Error", "An error occurred while creating the proxy service", "error");
                    console.log(error);
                });
            this.show = false;
            this.$emit('close')
        },

        onProviderChange(e) {
            this.formData.provider = e.value;
        },
        onAuthMethodChange(e) {
            this.formData.auth_method = e.value;
        }
    },

};
</script>

<template>
    <div>
        <b-button variant="primary" @click="show = true">
            <i class="fa fa-plus mr-1"></i>
            New Proxy Service
        </b-button>
        <Modal id="new_proxy_service" title="New Proxy Service" :visible.sync="show">
            <b-form>
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Service Name" label-for="service_name">
                            <b-form-input id="service_name" type="text" v-model="formData.service_name"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <label for="provider">Select a provider</label>
                        <ejs-dropdownlist id="provider" :dataSource="proxy_providers" cssClass="e-outline"
                                          :fields="providers_dropdownFields" @change="onProviderChange"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="API Endpoint" label-for="api_endpoint">
                            <b-form-input id="api_endpoint" type="text" v-model="formData.api_endpoint"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="GUID" label-for="guid">
                            <b-form-input id="guid" type="text" v-model="formData.guid"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <label for="auth_method">Authentication method</label>
                        <ejs-dropdownlist id="auth_method" :dataSource="auth_methods" cssClass="e-outline" @change="onAuthMethodChange"/>
                    </b-col>
                </b-row>
            </b-form>
            <template v-slot:footer>
                <b-button variant="secondary" @click="show = false">Cancel</b-button>
                <b-button variant="primary" @click="saveForm">Save</b-button>
            </template>
        </Modal>

    </div>
</template>

<style scoped>

</style>
