<script >
import {ColumnDirective, ColumnsDirective, GridComponent as EjsGrid} from "@syncfusion/ej2-vue-grids";
import Vue from "vue";

export default {
    name: 'ArchivedContracts',
    components: {
        'ejs-grid': EjsGrid,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
    },
    data() {
        return {
            dataSource: [
                {
                    product: 'AdVerifier',
                    service: 'targeted',
                    price: '$1.00',
                    start_date: '2023-01-01',
                    end_date: '2023-12-31',
                },
                {
                    product: 'EmailVerifier',
                    service: 'residential',
                    price: '$1.00',
                    start_date: '2023-01-01',
                    end_date: '2023-12-31',
                },
                {
                    product: 'AdVerifier',
                    service: 'crawler',
                    price: '$1.00',
                    start_date: '2023-01-01',
                    end_date: '2023-12-31',
                },
                {
                    product: 'EmailVerifier',
                    service: 'targeted',
                    price: '$1.00',
                    start_date: '2023-01-01',
                    end_date: '2023-12-31',
                }
            ]
        };
    },
    methods: {
        actionsTemplate: function () {
            return {
                template: Vue.component("actionsTemplate", {
                    template: `
                        <div class="d-flex" style="gap: 5px;">
                            <button
                                class="btn btn-primary"
                                @click="view"
                            >
                            <i class="fa fa-eye"></i>
                            </button>
                        </div>
                    `,
                    methods: {
                        edit() {
                            alert('Edit');
                        },
                        delete() {
                            alert('Delete');
                        }
                    }
                })
            };
        }
    },
    mounted() {
    },
};
</script>

<template>
    <div>
        <ejs-grid :dataSource="dataSource" allowPaging="true">
            <e-columns>
                <e-column field="product" headerText="Product" width="100"></e-column>
                <e-column field="service" headerText="Service" width="100"></e-column>
                <e-column field="price" headerText="Price" width="100"></e-column>
                <e-column field="start_date" headerText="Start Date" width="100"></e-column>
                <e-column field="end_date" headerText="End Date" width="100"></e-column>
                <e-column headerText="Actions" width="60" :template="actionsTemplate"></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<style scoped>

</style>
