<template>
    <div>
        <b-button variant="primary" @click="show = true">
            <i class="fa fa-plus mr-1"></i>
            New Pricing Contract
        </b-button>
        <Modal id="new_pricing_contract" title="New Pricing Contract" :visible.sync="show" size="sm">
            <b-form>
                <b-row class="mb-4 mt-4">
                    <b-col cols="6">
                        <ejs-dropdownlist placeholder="Provider" :dataSource="proxy_providers" cssClass="e-outline"
                                          floatLabelType="auto" :fields="proxy_dropdownFields"
                                          @change="onProviderChange"></ejs-dropdownlist>
                    </b-col>
                    <b-col cols="6">
                        <ejs-dropdownlist id="service" placeholder="Service" floatLabelType="auto"
                                          :dataSource="proxy_services" :fields="services_dropdownFields" cssClass="e-outline" @change="onServiceChange"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Price" label-for="price">
                            <b-input-group>
                                <b-input-group-prepend is-text>$</b-input-group-prepend>
                                <b-form-input id="price" v-model="formData.price" type="number" required/>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Date range" label-for="date_range">
                            <ejs-daterangepicker id="date_range" cssClass="e-outline"
                                                 @change="onDateRangeChange"></ejs-daterangepicker>
                        </b-form-group>
                    </b-col>

                </b-row>

            </b-form>
            <template v-slot:footer>
                <b-button variant="secondary" @click="show = false">Close</b-button>
                <b-button variant="primary" @click="saveForm">Save</b-button>
            </template>
        </Modal>

    </div>
</template>

<script>
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import {DateRangePickerComponent} from "@syncfusion/ej2-vue-calendars";
import Modal from "../utils/modal.vue";
import axios from "axios";
import swal from "sweetalert";

export default {
    name: 'NewPricingContract',
    components: {
        Modal,
        'ejs-dropdownlist': DropDownListComponent,
        'ejs-daterangepicker': DateRangePickerComponent,
    },
    props: {
        proxy_providers: {
            type: Array,
            required: true
        },

    },
    data() {
        return {
            show: false,
            proxy_dropdownFields: {text: 'provider_name', value: '_id'},
            proxy_services: [],
            services_dropdownFields: {text: 'service_name', value: '_id'},
            formData: {
                provider: '',
                service: '',
                price: '',
                start_date: '',
                end_date: '',
            }
        };
    },
    methods: {
        newPricingContract() {
            this.$emit('newPricingContract');
        },
        onProviderChange(event) {
            this.formData.provider = event.value;
            this.getServiceByProvider();
        },
        onServiceChange(event) {
            this.formData.service = event.value;
        },
        onDateRangeChange(event) {
            const {startDate, endDate} = event;
            this.formData.start_date = new Date(startDate);
            this.formData.end_date = new Date(endDate);
            console.log(this.formData);
        },


        getServiceByProvider() {
          axios.get(`${this.$root.serverUrl}/admin/proxy-services/provider/${this.formData.provider}`).then(
              (resp) => {
                  console.log(resp.data.data)
                  this.$root.preloader = false;
                  this.proxy_services = resp && resp.data && resp.data.data ? resp.data.data : [];
              }
          );
        },
        async saveForm() {
            const request = {
                apiKey: this.$root.apiKey,
                args: this.formData
            }
            axios.post(`${this.$root.serverUrl}/admin/pricing-contracts`, request).then(res => {
                    console.log(res.data)
                    this.$root.preloader = false;
                    if (res.data.error) {
                        swal('error', res.data.error, 'error');
                    } else {
                        swal('Success', 'Pricing contract created successfully', 'success');
                    }
                    this.show = false;
                    this.newPricingContract();
                }
            )
        }
    },

};
</script>

<style scoped>

</style>
