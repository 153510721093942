<template>
<!--    <div>-->
<!--        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-cog"></i>Rule Engine</h2>-->

<!--        <div id="app" class="container  card col-12" style="background-color: white; padding: 3%" >-->
<!--            <ejs-grid ref="editgrid" :dataSource='item.costPerClick' :editSettings='editSettings'  height='270' :toolbar='toolbar' @actionBegin="onActionBegin"   @actionComplete="onActionComplete">-->
<!--                <e-columns>-->
<!--                    <e-column field='product' headerText='Product' editType= 'dropdownedit' :edit='allProducts' width=100></e-column>-->
<!--                    <e-column field='targeted' headerText='Targeted Cost Per Click'   width=100></e-column>-->
<!--                    <e-column field='nationwide' headerText='Nationwide Cost Per Click'  width=100></e-column>-->
<!--                    &lt;!&ndash;                    <e-column field='reasonForUpdate' headerText='Reason For Update' editType= 'textbox' width=200 :isPrimaryKey='true'></e-column>&ndash;&gt;-->

<!--                </e-columns>-->
<!--            </ejs-grid>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="col-12 mt-4">-->
<!--        <div class="container-fluid mb-5 mt-4">-->
<!--            <div class="d-flex mt-4 mb-3 mr-3 justify-content-end">-->
<!--                <span>-->
<!--                    <button class="btn btn-sm btn-info" @click="create = true;">-->
<!--                        <i class="fa fa-money" ></i> Add Click Cost-->
<!--                    </button>-->
<!--                </span>-->
<!--            </div>-->

<!--            <div v-if="create" class="mb-5">-->
<!--                <div class="col-12">-->

<!--                    <div class="row mt-4">-->

<!--                        <div class="col-3 pl-4 pr-4">-->
<!--                            <label>Product <strong class="text-danger">*</strong></label>-->
<!--                            <select v-model="info.product" class="form-control border-dark">-->
<!--                                <option value="" selected>&#45;&#45; Any Product&#45;&#45;</option>-->
<!--                                <option v-for="product in products" :key="product.name" :value="product.name">{{product.name}}</option>-->
<!--                            </select>-->

<!--                        </div>-->
<!--                        <div class="col-3 pl-4 pr-4">-->
<!--                            <label>Targeted Cost Per Click<strong class="text-danger">*</strong></label>-->
<!--                            <input-->
<!--                                type="text"-->
<!--                                v-model="info.targeted"-->
<!--                                placeholder=""-->
<!--                                class="form-control required"-->
<!--                                required-->
<!--                            />-->
<!--                        </div>-->
<!--                        <div class="col-3 pl-4 pr-4">-->
<!--                            <label>NationWide Cost Per Click<strong class="text-danger">*</strong></label>-->
<!--                            <input-->
<!--                                type="text"-->
<!--                                v-model="info.nationwide"-->
<!--                                placeholder=""-->
<!--                                class="form-control required"-->
<!--                                required-->
<!--                            />-->
<!--                        </div>-->

<!--                        <div class="col-3 pl-4 pr-4 pt-4 mt-1" v-if="edit == false">-->
<!--                            <b-button-->
<!--                                size="md"-->
<!--                                variant="primary"-->
<!--                                @click="add"-->
<!--                                v-text="'Add'"-->
<!--                            />-->
<!--                        </div>-->
<!--                        <div class="col-3 pl-4 pr-4 pt-4 mt-1" v-else>-->
<!--                            <b-button-->
<!--                                size="md"-->
<!--                                variant="warning"-->
<!--                                @click="editItem"-->
<!--                                v-text="'Save Edit'"-->
<!--                            />-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div v-if="edit" class="form-text text-muted mt-2 p-2"><span class="text-danger">Edit Selection here</span></div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-12">-->
<!--                <ejs-grid ref="grid" :dataSource="item.costPerClick" :allowPaging="true" :allowSorting="true" :pageSettings="pageSettings">-->
<!--                    <e-columns>-->
<!--                        <e-column field='product' headerText='Product'></e-column>-->
<!--                        <e-column field='targeted' headerText='Targeted Cost Per Click'></e-column>-->
<!--                        <e-column field='nationwide' headerText='Nationwide Cost Per Click'></e-column>-->
<!--                        <e-column headerText='Action' :template="actionTemplate" @deleteInfo="deleteInfo"></e-column>-->
<!--                    </e-columns>-->
<!--                </ejs-grid>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <div class="col-12">


    <div class="row">
        <p>Product Zone Config</p>
    </div>
        <div class="card p-4 mb-5">
            <div class="row  text-center m-0">
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Product</th>
                            <th>Provider</th>
                            <th>Zone</th>
                            <th>Type</th>
                            <th>Rate</th>
                            <th>Time Period Of Contract</th>
                            <th>Status</th>
                            <th>Edit</th>
                        </tr>
                        </thead>
                        <tbody v-for="(productItem, pIndex) in item.product" :key="pIndex" class="table-group-divider">
                        <tr v-for="(zoneItem, index) in productItem.zones" :key="index">
                            <td class="table-primary">{{ productsName(productItem.product) }}</td> <!-- Leave this cell empty for the product name in sub-rows -->
                            <td class="table-active">{{ zoneItem.provider }}</td>
                            <td class="table-active">{{ zoneItem.zone }}</td>
                            <td class="table-active">{{ zoneItem.type }}</td>
                            <td class="table-active">{{ zoneItem.rate }}</td>
                            <td class="table-active text-success" v-if="zoneItem.dateRange != undefined">{{ `${zoneItem.dateRange.oldStartDate}  - ${zoneItem.dateRange.oldEndDate}` }}</td>
                            <td class="table-active text-danger" v-else> Enter Date Period</td>
                            <td class="table-active">
                                <div>
                                    <b-form-checkbox v-model="zoneItem.active" switch size="lg"><div :class="{ 'text-success': zoneItem.active, 'text-danger': !zoneItem.active}">Active</div></b-form-checkbox>
                                </div>
                            </td>
                            <td class="table-active">
                                <div>
                                    <b-form-checkbox v-model="zoneItem.status" switch size="lg"><div :class="{ 'text-success': zoneItem.status, 'text-danger': !zoneItem.status}">Active</div></b-form-checkbox>
                                </div>
                            </td>
                            <td class="table-active">
                                <!-- Add an "Edit" button with an icon -->
                                <div class="btn-group" role="group" aria-label="Basic example">

                                <button class="btn btn-primary btn-sm" @click="editRow(productItem, zoneItem)">
                                    <i class="fa fa-pencil"></i> Edit
                                </button>
                                    <button class="btn btn-danger btn-sm" @click="deleteRow(productItem, zoneItem)">
                                        <i class="fa fa-trash"></i> Delete
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="row mt-4 justify-content-center  d-flex">
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <label>Product</label>
<!--                            <input type="text" v-model="newItem.product" class="form-control required">-->
                            <select v-model="newItem.product" class="form-control ">
                                <option value="" selected>-- Any Product--</option>
                                <option v-for="product in products" :key="product.identifier" :value="product.identifier">{{product.name}}</option>
                            </select>
                        </div>
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <label>Provider</label>
                            <select v-model="newItem.provider" class="form-control">
                                <option value="" selected>-- all --</option>
                                <option v-for="(item,index) in providers" :key="index" :value="item">{{item}}</option>
                            </select>
                        </div>
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <label>Zone</label>
                            <input type="text" v-model="newItem.zone" class="form-control required">
                        </div>
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <label>Type</label>
                            <select v-model="newItem.type" class="form-control">
                                <option v-for="(item,index) in type" :key="index" :value="item">{{item}}</option>
                            </select>
                        </div>


                    </div>
                    <div class="row mt-4 justify-content-center  d-flex">
                        <div class="col-sm-2 col-md-2 col-lg-2">
                            <label>Rate</label>
                            <input type="text" v-model="newItem.rate" class="form-control required" @input="validateNumberInput" >
                        </div>



<!--                        <div class="col-sm-4 col-md-4 col-lg-4">-->
<!--                            <div class="border border-danger row  ">-->
<!--                                <div class="col-sm-6 col-md-6 col-lg-6">-->
<!--                            <label>Rate Start Date</label>-->
<!--                            <input type="date" v-model="newItem.startDate" class="form-control date" placeholder="From"/>-->
<!--                                </div>-->
<!--                                <div class="col-sm-6 col-md-6 col-lg-6">-->
<!--                                    <label>Rate End Date</label>-->
<!--                                    <input type="date" v-model="newItem.endDate" class="form-control date" placeholder="To"/>-->
<!--                                </div>-->
<!--                        </div>-->

<!--                        </div>-->

                        <div class="col-sm-4 col-md-4 col-lg-4" v-if="edit == true">
                            <div class="border border-danger row relative-position">
                                <!-- Circular button in top-right corner -->
                                <button class="circle-button" v-if="newItem.dateRange.oldStartDate != ''"
                                        data-toggle="modal" data-target="#exampleModalCenter" @click="showModal"><span class="font-weight-bolder fa fa-plus "></span></button>

                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <label>Rate Start Date</label>
                                    <input type="date" v-model="newItem.dateRange[dateType[0]]" class="form-control date" placeholder="From"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <label>Rate End Date</label>
                                    <input type="date" v-model="newItem.dateRange[dateType[1]]" class="form-control date" placeholder="To"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4  d-flex" v-else>
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <label>Rate Start Date</label>
                                    <input type="date" v-model="newItem.dateRange.oldStartDate" class="form-control date" placeholder="From"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <label>Rate End Date</label>
                                    <input type="date" v-model="newItem.dateRange.oldEndDate" class="form-control date" placeholder="To"/>
                                </div>
                        </div>



                        <div class="col-sm-2 col-md-2 col-lg-2 mt-4" v-if="edit == false">
                            <button @click="addItem" class="btn btn-dark btn-block">Add Item</button>
                        </div>
                        <div class="col-sm-2 col-md-2 col-lg-2 mt-4" v-else>
                            <button @click="saveEdit" class="btn btn-warning btn-block">Edit Item</button>
                        </div>
                    </div>
<!--                    <div class="p-3 text-primary font-weight-bold">Cancel Edit</div>-->
                    <div class="p-3" v-if="edit == true">
                        <a class="text-primary font-weight-bold underline-on-hover" @click="resetValue">Cancel Edit</a>
                    </div>

                </div>

            </div>
        </div>

        <b-modal ref="my-modal" hide-footer title="Select Date Period">
            <div class="d-block text-center">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col" >Contract</th>
                        <th scope="col" >Start Date</th>
                        <th scope="col" >End Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr @click="handleRowClick('old')">
                        <td>Current Contract</td>
                        <td class="text-success">{{newItem.dateRange.oldStartDate }}</td>
                        <td class="text-success">{{newItem.dateRange.oldEndDate }}</td>
                    </tr>
                    <tr @click="handleRowClick('new')" v-if="newItem.dateRange.newStartDate != '' && newItem.dateRange.newEndDate != '' ">
                        <td>New Contract</td>
                        <td class="text-warning">{{newItem.dateRange.newStartDate}}</td>
                        <td class="text-warning">{{newItem.dateRange.newEndDate}}</td>
                    </tr>
                    <tr @click="handleRowClick('new')" v-else>
                        <td>New Contract</td>
                        <td class="text-danger">Click to add Date</td>
                        <td class="text-danger">Click to add Date</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close</b-button>
        </b-modal>

    </div>
</template>
<script>
import Vue from "vue";
import swal from "sweetalert";
import axios from "axios";
import InputTag from 'vue-input-tag';
Vue.component('input-tag', InputTag)

Vue.prototype.$eventHub = new Vue();

export default {
    name: "devicesRule",
    props: ["item"],
    beforeDestroy() {
        this.$eventHub.$off('delete');
        this.$eventHub.$off('edit');
    },
    watch: {
        "item.product": {
            handler() {
                console.log("updated")
                this.$forceUpdate()
            },
            deep: true
        }
    },
    created() {

        this.loadProducts();
        this.loadProviders();

     //   const vm = this; // Store a reference to the Vue component

        this.$eventHub.$on('delete', (index) => {
            this.deleteInfo(index);
            this.$refs.grid.refresh()
            this.$forceUpdate();// Access the deleteInfo method using the reference
        });
        this.$eventHub.$on('edit', (data) => {
            // this.deleteInfo(index);
            this.info = data.data
            this.create = true
            this.edit = true
            this.editIndex = data.index
            // this.$refs.grid.refresh()
            // this.$forceUpdate();// Access the deleteInfo method using the reference
        });

        // this.$eventHub.$on('delete', (i)=>{
        //     this.$emit('deleteInfo', i);
        //     this.$nextTick(() => {
        //         this.$forceUpdate();
        //     })
        // });
    },
    computed: {},
    components: {},
    data: function () {
        return {
            info: {},
            create: false,
            edit:false,
            editProductIndex:{product:0,zone:0},
            pageSettings: {pageSize: 100},
            products:[],
            providers:[],
            type:['nationwide','targeted'],
            dateType:['oldStartDate','oldEndDate'],
            editIndex:'',
                newItem: {
                    product: '',
                    provider: '',
                    zone: '',
                    type:'',
                    rate:'',
                    dateRange:{
                        oldStartDate: '1',
                        oldEndDate: '',
                        newStartDate: '', // Updated start date
                        newEndDate: '',   // Updated end date
                        isActive: false, // Indicates if the updated date range is active
                    },
                },
            actionTemplate: () => {
                return {
                    template: Vue.component('tosTemplate', {
                        template: `
                                  <div class="row">
                                    <div class="col-6 justify-content-end">
                                      <button @click="edit" class='btn btn-warning btn-sm'>
                                        <span class="fa fa-pencil" title="Remove"></span>
                                      </button>
                                      <button @click="remove()" class='btn btn-primary btn-sm'>
                                        <span class="fa fa-trash" title="Remove"></span>
                                      </button>
                                    </div>
                                  </div>`,
                        data: function () {
                            return {
                                data: {}
                            }
                        },
                        methods: {
                            remove: function () {
                                swal({ title: "Remove", text: "Are you sure", icon: "info", buttons: ['No', 'Yes'] }).then((val) => {
                                    if (!val) return;
                                    this.$eventHub.$emit('delete', this.data.index); // Access the delete event using the reference// Emit the delete event with the index
                                });
                            },
                            edit: function () {
                                    this.$eventHub.$emit('edit', {data:this.data,index:this.data.index}); // Access the delete event using the reference// Emit the delete event with the index
                            },
                        }
                    })
                }
            },



        };
    },
    methods: {
        change() {
            console.log("changeing")
        },
        validateNumberInput() {
            // Remove any non-numeric characters, except for the decimal point
            this.newItem.rate = this.newItem.rate.replace(/[^0-9.]/g, '');
        },
        valueSelect(type){
            if(type == 'old'){
            return this.dateType = ['oldStartDate','oldEndDate']
            }
            return this.dateType = ['newStartDate','newEndDate']

        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        handleRowClick(columnIndex) {
            this.valueSelect(columnIndex)
            this.hideModal()
    // You can trigger an event or perform any action here based on the column index
            alert(`Column ${columnIndex} clicked.`);
        },

        hasEmptyValue(obj) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] === '') {
                    return true; // Found an empty value
                }
            }
            return false; // No empty values found
        },
        productsName(name){
            switch (name) {
                case 'email':
                    return 'Email Verifier'
                case 'domain':
                    return 'AdVerifier'
                case 'pushGiant':
                    return 'Push Giant'
                default:
                    return name
            }
        },
        saveEdit(){
            this.edit = false
            let index = this.editProductIndex
            console.log(this.newItem.dateRange,'index')
            this.item.product[index.product].zones[index.zone] = { provider:this.newItem.provider, zone:this.newItem.zone, type:this.newItem.type, rate:this.newItem.rate,dateRange:this.newItem.dateRange, active: this.newItem.active };
            this.resetValue()
            this.$forceUpdate();
        },
        editRow: function(productItem, zoneItem){
             this.edit = true
             this.newItem.product  = productItem.product
             this.newItem.provider = zoneItem.provider
                 this.newItem.zone = zoneItem.zone
                 this.newItem.type = zoneItem.type
                 this.newItem.rate = zoneItem.rate
             this.newItem.dateRange = zoneItem.dateRange || { oldStartDate: '', oldEndDate: '',newStartDate: ''
                                                            ,newEndDate: '',isActive: true, }


            this.findIndexes(productItem, zoneItem)
        },
        findIndexes(productItem, zoneItem){
            const productIndex = this.item.product.findIndex(item => item.product === productItem.product);

            // Check if the product exists
            if (productIndex !== -1) {
                // Find the index of the zone in the zones array of the product
                const zoneIndex = this.item.product[productIndex].zones.findIndex(item => item.zone === zoneItem.zone);

                if (zoneIndex !== -1) {

                    this.editProductIndex.product = productIndex
                    this.editProductIndex.zone = zoneIndex
                    console.log('deleted',{a:productIndex,b:zoneIndex})
                    return {a:productIndex,b:zoneIndex}

                }
                return {a:'Null',b:'Null'}
            }

        },
        deleteRow: function(productItem, zoneItem){
            swal({ title: "Remove", text: "Are you sure this is final once you click Update Settings", icon: "info", buttons: ['No', 'Yes'] }).then((val) => {
                if (!val) return;

            let index =  this.findIndexes(productItem, zoneItem)

            if(index.a != 'Null'){
                console.log(index.b,'indexes')
                this.item.product[index.a].zones.splice(index.b, 1);
                this.$forceUpdate();
            }else{
                console.log('could not delete')
            }
            });
        },
        addItem: function () {
            const newData = { provider:this.newItem.provider, zone:this.newItem.zone, type:this.newItem.type, rate:this.newItem.rate,dateRange:this.newItem.dateRange, active: false };
            const validate = { provider:this.newItem.provider, type:this.newItem.type, rate:this.newItem.rate,dateRange:this.newItem.dateRange };
            if (this.hasEmptyValue(validate)) {
                return swal({title: 'Error', text: "Please fill out all required fields", icon: 'error'})
            }
            const productIndex =this.item.product.findIndex((item) => item.product === this.newItem.product);

            // Check if the product was found
            if (productIndex !== -1) {
                // Push the new object into the zones array of the found product
               this.item.product[productIndex].zones.push(newData);
                console.log(`Product "${this.newItem.product}"found.`);
            } else {
                // Product not found, you can handle this case as needed
                console.log(`Product "${this.newItem.product}"not found.`);

            // Push the new item to the products array
            this.item.product.push({
                product: this.newItem.product,
                zones: [
                    {
                        provider: this.newItem.provider,
                        zone: this.newItem.zone,
                        type: this.newItem.type,
                        rate: this.newItem.rate,
                        active: false,
                        dateRange:this.newItem.dateRange
                    }
                ]
            });
            }
            // Clear the input fields
            this.resetValue()
            this.$forceUpdate();
            console.log(this.item.product)
        },
        resetValue(){
            this.newItem.product = '';
            this.newItem.provider = '';
            this.newItem.zone = '';
            this.newItem.type = '';
            this.newItem.rate = '';
            // this.newItem.dateRange.oldStartDate = '';
            // this.newItem.dateRange.oldEndDate = '';
            // this.newItem.dateRange.newStartDate = '';
            // this.newItem.dateRange.newEndDate = '';
            this.newItem.dateRange = {
                oldStartDate: '',
                oldEndDate: '',
                newStartDate: '', // Updated start date
                newEndDate: '',   // Updated end date
                isActive: false,
            }
            this.edit = false
        },
        deleteInfo(index) {
            if (index >= 0 && index < this.item.costPerClick.length) {
                this.item.costPerClick.splice(index, 1); // Remove the item at the specified index
                this.$refs.grid.refresh()
                this.$forceUpdate();
            }
        },
        loadProviders: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey, provider:this.provider};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/providers/list`, {params:request}).then(function(resp){
                //Store the stats
                this.providers = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        loadProducts: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.products = (resp.data && resp.data.data)? resp.data.data : [];
                if(this.products.length != 0) this.products = this.products.filter((item) => item.identifier != 'catcher')
            }.bind(this));
            // return this.products
        },
        add() {
            if (!this.info.product || !this.info.nationwide || !this.info.targeted) {
                return swal({title: 'Error', text: "Please fill out all required fields", icon: 'error'})
            }else{

               this.item.costPerClick.push({product:this.info.product,nationwide:this.info.nationwide,targeted:this.info.targeted})
                this.info ={}
                this.$refs.grid.refresh()
                this.$forceUpdate();
            }

        },
        editItem() {
            if (!this.info.product || !this.info.nationwide || !this.info.targeted) {
                return swal({title: 'Error', text: "Please fill out all required fields", icon: 'error'})
            }else{
                this.item.costPerClick[this.editIndex] = {product:this.info.product,nationwide:this.info.nationwide,targeted:this.info.targeted}
                this.info ={}
                this.edit = false
                this.$refs.grid.refresh()
                this.$forceUpdate();
            }

        }
    }
};
</script>
<!--<script>-->
<!--import axios from "axios";-->
<!--import swal from "sweetalert";-->
<!--import Swal from "sweetalert2";-->
<!--import { Page, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";-->
<!--import {DropDownListPlugin,MultiSelectPlugin} from "@syncfusion/ej2-vue-dropdowns";-->
<!--// import {DataManager, UrlAdaptor} from '@syncfusion/ej2-data';-->
<!--import Vue from "vue";-->
<!--import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";-->
<!--Vue.use(DropDownListPlugin);-->
<!--Vue.use(MultiSelectPlugin);-->

<!--export default {-->
<!--    name: "clickCost",-->
<!--    props: ["user",'item'],-->
<!--    // components: {nightlyConf},-->
<!--    data: function () {-->
<!--        return {-->
<!--            selectedRowIndex: -1,-->
<!--            data: {json:[]},-->
<!--            editdata: {json:[]},-->
<!--            oldvalue : {},-->
<!--            reasonForUpdate:'',-->
<!--            valueObject:{},-->
<!--            dataTypes:[],-->
<!--            typeInput:'',-->
<!--            editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },-->
<!--            toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],-->
<!--            allProducts: { params: {  fields: { text: 'product', value: 'product' }, allowFiltering: true,}},-->
<!--            fields: { text: 'text', value: 'value' },-->
<!--            addOne:true,-->
<!--        };-->
<!--    },-->

<!--    created: function () {-->
<!--        this.data.json = this.item.costPerClick-->
<!--        this.$root.preloader = false;-->
<!--        // this.getData()-->
<!--        this.loadProducts()-->
<!--    },-->
<!--    methods: {-->
<!--        loadProducts: function(){-->
<!--            //Load the clients-->
<!--            var request = {'apikey': this.$root.apikey};-->
<!--            //this.$root.preloader = true;-->

<!--           return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){-->
<!--                //Store the stats-->
<!--                let pro = (resp.data && resp.data.data)? resp.data.data : [];-->
<!--               this.allProducts.params.dataSource = pro.map(item => {-->
<!--                   return {-->
<!--                       Id: item._id.toString(),-->
<!--                       product: item.name // Set the default value for 'type'-->
<!--                       // Convert the 'id' to a string and use it as 'Id'-->
<!--                   };-->
<!--               });-->
<!--               this.$forceUpdate();-->
<!--                //this.$root.preloader = false;-->
<!--            }.bind(this));-->
<!--            // return this.products-->
<!--        },-->
<!--        async openInputDialog(data) {-->
<!--            const { value: inputText } = await Swal.fire({-->
<!--                title: 'Reason For Update?',-->
<!--                input: 'text',-->
<!--                inputPlaceholder: 'Reason For Update',-->
<!--                inputValidator: (value) => {-->
<!--                    if (!value) {-->
<!--                        return 'You must enter something!';-->
<!--                    }-->
<!--                },-->
<!--                showCancelButton: true,-->
<!--                confirmButtonText: 'Submit'-->
<!--            });-->

<!--            if (inputText) {-->
<!--                this.reasonForUpdate = inputText-->
<!--                this.save(data)-->
<!--            }-->
<!--        },-->
<!--        onActionBegin(args) {-->
<!--            console.log('actionbigin',args)-->
<!--            if (args.requestType === 'beginEdit') {-->
<!--                const rowInfo = this.$refs.editgrid.getRowInfo(args.row);-->
<!--                this.selectedRowIndex = rowInfo.rowIndex;-->
<!--                this.addOne = false// Set the selected index-->
<!--                // const updatedData = args;-->
<!--                // console.log('this.selectedRowIndex',updatedData)-->
<!--                // console.log(this.selectedRowIndex)-->
<!--                // if(this.selectedRowIndex != -1){-->
<!--                //    this.item.costPerClick[this.selectedRowIndex] = args.data-->
<!--                //     this.addOne = false-->
<!--                // }-->

<!--            }-->
<!--        },-->
<!--        onActionComplete(args) {-->
<!--            if (args.requestType === 'save') {-->

<!--              const updatedData = args.data;-->
<!--              console.log(args.data,'save')-->
<!--             if(!this.addOne) this.item.costPerClick[this.selectedRowIndex] = updatedData-->
<!--                this.$emit('addClickCost');-->
<!--                this.$refs.editgrid.refresh()-->


<!--            }else if(args.requestType === 'delete'){-->
<!--                // Use the filter method to remove the matching object based on name and age-->
<!--                const rowInfo = this.$refs.editgrid.getRowInfo(args.row);-->
<!--                this.selectedRowIndex = rowInfo.rowIndex;-->
<!--                console.log( args,'delete')-->
<!--                this.item.costPerClick.splice( this.selectedRowIndex, 1);-->
<!--                // const newArray =  this.item.costPerClick.filter(item =>-->
<!--                //     !Object.keys(item).every(key =>-->
<!--                //         item[key] === args.data[key]-->
<!--                //     )-->
<!--                // );-->
<!--                // this.item.costPerClick = newArray-->
<!--                this.$emit('addClickCost');-->
<!--                this.$refs.editgrid.refresh()-->

<!--                // this.deleteRecord(args.data)-->
<!--            }-->
<!--        },-->

<!--        toolbarClick: function(args = ClickEventArgs) {-->
<!--            // this.$refs.grid.showSpinner();-->
<!--            // switch (args.item.text) {-->
<!--            console.log(args)-->
<!--            // }-->
<!--            // switch (args.item.text) {-->
<!--            //     /* tslint:disable */-->
<!--            //     case 'Update':-->
<!--            //         this.updateRecord()-->
<!--            //         break;-->
<!--            //     /* tslint:enable */-->
<!--            //-->
<!--            //     case 'Delete':-->
<!--            //         (this.$refs.grid).csvExport(this.getCsvExportProperties());-->
<!--            //         break;-->
<!--            // }-->
<!--        },-->

<!--        updateRecord() {-->
<!--            // Your logic to update a record-->
<!--        },-->
<!--        cancelEdit() {-->
<!--            // Your logic to cancel edit mode-->
<!--        },-->
<!--        getData(){-->

<!--            //Load the settings-->
<!--            var request = { apikey: this.$root.apikey };-->


<!--            axios-->
<!--                .get(`${this.$root.serverUrl}/admin/RuleEngine`, { params: request })-->
<!--                .then(-->

<!--                    function (resp) {-->
<!--                        console.log(resp.data.data)-->
<!--                        this.$root.preloader = false;-->
<!--                        let count = (resp.data) ? resp.data.Count : 0;-->
<!--                        let data = (resp.data) ? resp.data.Items : [];-->
<!--                        this.data = {Items:data,Count:count}-->
<!--                        this.data.json = data-->
<!--                        this.oldvalue = data-->
<!--                        this.$forceUpdate();-->
<!--                    }.bind(this)-->
<!--                );-->
<!--        },-->

<!--        updatedate(data){-->
<!--            if(data["_id"]){-->
<!--                this.openInputDialog(data)-->
<!--            }else{-->
<!--                this.save(data)-->
<!--            }-->
<!--        },-->
<!--        save(data) {-->

<!--            // if (!this.device.os || !this.device.isMobile || !this.device.versions) {-->
<!--            //     return swal({title: 'Error', text: "Please fill out all required fields", icon: 'error'})-->
<!--            // }-->

<!--            this.$emit('addClickCost', data);-->
<!--            this.$nextTick(() => {-->
<!--                this.$forceUpdate();-->
<!--                // this.device = {};-->
<!--            })-->
<!--            //data.user = this.user-->
<!--            // var request = { apikey: this.$root.apikey, args: data,oldvalue:this.valueObject, user:this.user, reasonForUpdate:this.reasonForUpdate };-->
<!--            //-->
<!--            //-->
<!--            // axios({-->
<!--            //     url: `${this.$root.serverUrl}/admin/RuleEngine`,-->
<!--            //     data: request,-->
<!--            //     responseType: "json",-->
<!--            //     method: data["_id"] ? "PUT" : "POST",-->
<!--            // }).then(function (resp)  {-->
<!--            //     if(resp.data.error){-->
<!--            //         swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {-->
<!--            //-->
<!--            //         })-->
<!--            //     }else{-->
<!--            //         swal({ title: 'Success', text: 'Data Saved', icon: 'success' }).then(() => {-->
<!--            //             this.getData()-->
<!--            //             this.$forceUpdate();-->
<!--            //             // this.this.$refs.editgrid.refresh()-->
<!--            //         })-->
<!--            //     }-->
<!--            //-->
<!--            // }.bind(this)).catch(() => {-->
<!--            //     swal("Oops!", "There was an issue Resting", "error");-->
<!--            // })-->
<!--        },-->
<!--        deleteRecord(data) {-->


<!--            var request = { apikey: this.$root.apikey, args: data };-->


<!--            axios({-->
<!--                url: `${this.$root.serverUrl}/admin/RuleEngine`,-->
<!--                data: request,-->
<!--                responseType: "json",-->
<!--                method: "Delete"-->
<!--            }).then(function (resp)  {-->
<!--                if(resp.data.error){-->
<!--                    swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {-->

<!--                    })-->
<!--                }else{-->
<!--                    swal({ title: 'Success', text: 'Data Updated', icon: 'success' }).then(() => {-->
<!--                        this.getData()-->
<!--                        this.$forceUpdate();-->
<!--                        // this.this.$refs.editgrid.refresh()-->
<!--                    })-->
<!--                }-->

<!--            }.bind(this)).catch(() => {-->
<!--                swal("Oops!", "There was an issue Resting", "error");-->
<!--            })-->
<!--        },-->
<!--    },-->
<!--    provide: {-->
<!--        grid: [Page, Edit, Toolbar]-->
<!--    }-->
<!--}-->
<!--</script>-->
<style>
/* Circular button styles */
.circle-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red; /* Change the background color as needed */
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    z-index: 1;
    pointer-events: auto;
//cursor: pointer;
}
.toggle.btn {
    min-width: 104px !important;
}
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
