<script>
import swal from "sweetalert2";
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import axios from "axios";

export default {
    name: 'EditProvider',
    components: {
        'ejs-dropdownlist': DropDownListComponent,
    },
    props: {
        provider: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            localProvider: {
                provider_name: '',
                address: '',
                phone: '',
                key_contacts: [],
                support_contacts: [],
                api_endpoint: '',
                guid: '',
                selected_auth_method: '',
            },
            new_key_contact: {
                name: '',
                email_id: '',
                phone: ''
            },
            new_support_contact: {
                name: '',
                email_id: '',
                phone: ''
            },
            auth_methods: ['SFA', 'MFA', '2FA'],
            services_provided: '',
            fields: [
                {key: 'name', label: 'Name'},
                {key: 'email_id', label: 'Email ID'},
                {key: 'phone', label: 'Phone'},
                {key: 'actions', label: 'Actions'}
            ]
        };
    },
    watch: {
        provider: {
            handler(newVal) {
                const parsedProvider = typeof newVal === 'string' ? JSON.parse(newVal) : newVal;
                if( parsedProvider.hasOwnProperty('column')){
                    delete parsedProvider.column;
                }
                if( parsedProvider.hasOwnProperty('index')){
                    delete parsedProvider.index;
                }
                if (parsedProvider.hasOwnProperty('id')){
                    delete parsedProvider.id;
                }
                this.localProvider = {...parsedProvider};
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        editProvider() {
            const request = {
                apiKey: this.$root.apiKey,
                args: this.localProvider
            }
            axios.patch(`${this.$root.serverUrl}/admin/proxy-providers`, request)
                .then(() => {
                    swal.fire({
                        title: "Success",
                        text: "Provider edited successfully",
                        icon: "success",
                    });
                }).catch(() => {
                swal.fire({
                    title: "Error",
                    text: "An unexpected error occurred",
                    icon: "error",
                });
            });
        },
        addKeyContract() {
            this.localProvider.key_contacts.push(this.new_key_contact);
            this.new_key_contact = {
                name: '',
                email_id: '',
                phone: '',
            };
        },
        deleteKeyContract(item) {
            swal.fire({
                title: "Remove Key Contact",
                text: "Are you sure?",
                icon: "question",
            }).then((willDelete) => {
                if (willDelete) {
                    this.localProvider.key_contacts = this.localProvider.key_contacts.filter(contact => contact !== item);
                    swal.fire({
                        title: "Success",
                        text: "Key contact removed",
                        icon: "success",
                    });
                }
            });
        },
        addSupportContract() {
            this.localProvider.support_contacts.push(this.new_support_contact);
            this.new_support_contact = {
                name: '',
                email_id: '',
                phone: '',
            };
        },
        deleteSupportContract(item) {
            swal.fire({
                title: "Remove Support Contact",
                text: "Are you sure?",
                icon: "question",
            }).then((willDelete) => {
                if (willDelete) {
                    this.localProvider.support_contacts = this.localProvider.support_contacts.filter(contact => contact !== item);
                    swal.fire({
                        title: "Success",
                        text: "Support contact removed",
                        icon: "success",
                    });
                }
            });
        },
        close() {
            this.$emit('close');
        }
    },
};

</script>

<template>
    <div>
        <div class="modal fade" v-if="visible" :class="{ show: visible }" id="edit_provider_modal" role="dialog"
             aria-labelledby="modalLabel" :aria-hidden="!visible">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Edit Provider</h4>
                        <button
                            type="button"
                            class="close"
                            @click="close"
                        >
                            &times;
                        </button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="container-fluid">
                            <b-row>
                                <b-col cols="4">
                                    <b-form-group
                                        label="Provider Name"
                                        label-for="provider_name"
                                    >
                                        <b-form-input
                                            id="provider_name"
                                            v-model="localProvider.provider_name"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                        label="Address"
                                        label-for="address"
                                    >
                                        <b-form-input
                                            id="address"
                                            v-model="localProvider.address"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-group label="Phone Number" label-for="phone_number">
                                        <b-form-input
                                            id="phone_number"
                                            v-model="localProvider.phone"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <h6><strong>Key Contacts</strong></h6>
                            <b-row>
                                <b-col cols="4">
                                    <b-form-group
                                        label="Name"
                                        label-for="key_contact_name"
                                    >
                                        <b-form-input
                                            id="key_contact_name"
                                            v-model="new_key_contact.name"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                        label="Email"
                                        label-for="key_contact_email"
                                    >
                                        <b-form-input
                                            id="key_contact_email"
                                            v-model="new_key_contact.email_id"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-group
                                        label="Phone"
                                        label-for="key_contact_phone"
                                    >
                                        <b-form-input
                                            id="key_contact_phone"
                                            v-model="new_key_contact.phone"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="1" class="d-flex justify-content-center align-items-center">
                                    <b-button
                                        variant="primary"
                                        @click="addKeyContract"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" v-if="localProvider.key_contacts.length > 0">
                                    <b-table :items="localProvider.key_contacts" :fields="fields" striped>
                                        <template #cell(actions)="data">
                                            <b-button size="sm" variant="danger" @click="deleteKeyContract(data.item)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </template>

                                    </b-table>
                                </b-col>
                                <b-col cols="12" class="d-flex justify-content-center align-items-center" v-else>
                                    <strong>No key contacts added</strong>
                                </b-col>
                            </b-row>

                            <h6><strong>Support Contracts</strong></h6>
                            <b-row>
                                <b-col cols="4">
                                    <b-form-group
                                        label="Name"
                                        label-for="support_contact_name"
                                    >
                                        <b-form-input
                                            id="support_contact_name"
                                            v-model="new_support_contact.name"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                        label="Email"
                                        label-for="support_contact_email"
                                    >
                                        <b-form-input
                                            id="support_contact_email"
                                            v-model="new_support_contact.email_id"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-group
                                        label="Phone"
                                        label-for="support_contact_phone"
                                    >
                                        <b-form-input
                                            id="support_contact_phone"
                                            v-model="new_support_contact.phone"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="1" class="d-flex justify-content-center align-items-center">
                                    <b-button
                                        variant="primary"
                                        @click="addSupportContract"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" v-if="localProvider.support_contacts.length > 0">
                                    <b-table :items="localProvider.support_contacts" :fields="fields" striped>
                                        <template #cell(actions)="data">
                                            <b-button size="sm" variant="danger"
                                                      @click="deleteSupportContract(data.item)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </template>

                                    </b-table>
                                </b-col>
                                <b-col cols="12" class="d-flex justify-content-center align-items-center" v-else>
                                    <strong>No support contacts added</strong>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="4">
                                    <b-form-group
                                        label="API Endpoint"
                                        label-for="api_endpoint"
                                    >
                                        <b-form-input
                                            id="api_endpoint"
                                            v-model="localProvider.api_endpoint"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                        label="GUID"
                                        label-for="guid"
                                    >
                                        <b-form-input
                                            id="guid"
                                            v-model="localProvider.guid"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-group
                                        label="Auth Method"
                                        label-for="auth_method"
                                    >
                                        <ejs-dropdownlist
                                            id="auth_method"
                                            :dataSource="auth_methods"
                                            cssClass="e-outline"
                                            v-model="localProvider.auth_method"
                                        ></ejs-dropdownlist>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <b-button variant="secondary" @click="close">Cancel</b-button>
                        <b-button variant="primary" @click="editProvider">Edit</b-button>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop" @click="close" v-if="visible"></div>
        </div>
    </div>
</template>

<style>

.modal .fade {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1150 !important;
}

.modal-dialog {
    min-width: 600px;
    max-width: 1200px;
    margin: 30px auto;
}

.modal-content {
    z-index: 1150 !important;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;

}

.modal-header {
    background: #0d0d48;
    color: #ffffff;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 1px solid #ddd;
}

.modal-title {
    margin: 0;
}

.close {
    color: #ffff;
    font-size: 1.8rem;
    font-weight: bold;
    border: none;
    background: transparent;
}

.close:hover {
    color: #ffff;
    text-decoration: none;
    cursor: pointer;
}

.modal-body {
    padding: 15px;
    min-height: 50vh;
    max-height: 75vh;
    overflow: auto;
}

.show {
    display: block;
}

.modal-backdrop {
    z-index: 940 !important;
}

</style>
