<template>
    <div class="modal fade" v-if="visible" :class="{ show: visible }" :id="id" role="dialog" aria-labelledby="modalLabel" :aria-hidden="!visible">

        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                    <button
                        type="button"
                        class="close"
                        @click="handleClose"
                    >
                        &times;
                    </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <slot></slot>
                    </div>
                </div>
                <div class="modal-footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
        <div class="modal-backdrop" @click="handleClose" v-if="visible"></div>
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
        title: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'md',
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:visible', false);
        },
    },
};
</script>

<style>
.modal .fade {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1150 !important;
}

.modal-dialog {
    min-width: 600px;
    max-width: 1200px;
    margin: 30px auto;
}

.modal-content {
    z-index: 1150 !important;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;

}

.modal-header {
    background: #0d0d48;
    color: #ffffff;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 1px solid #ddd;
}

.modal-title {
    margin: 0;
}

.close {
    color: #ffff;
    font-size: 1.8rem;
    font-weight: bold;
    border: none;
    background: transparent;
}

.close:hover {
    color: #ffff;
    text-decoration: none;
    cursor: pointer;
}

.modal-body {
    padding: 15px;
    min-height: 50vh;
    max-height: 75vh;
    overflow: auto;
}

.show {
    display: block;
}
.modal-backdrop {
    z-index: 940 !important;
}

</style>
