<script>
import {ColumnDirective, ColumnsDirective, GridComponent as EjsGrid} from '@syncfusion/ej2-vue-grids';
import {DropDownListComponent} from '@syncfusion/ej2-vue-dropdowns';
import Vue from "vue";
import swal from "sweetalert";
import axios from "axios";
import NewPricingContract from "@/components/settings/proxy_providers/pircing_contracts/new_pricing_contract.vue";
import EditPricingContract from "@/components/settings/proxy_providers/pircing_contracts/edit_pricing_contract.vue";

let Bus = new Vue();
export default {
    name: 'PricingContracts',
    components: {
        EditPricingContract,
        NewPricingContract,
        'ejs-grid': EjsGrid,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
        'ejs-dropdownlist': DropDownListComponent,
    },
    data() {
        return {
            dataSource: [],
            proxy_providers: [],
            editPricingContract: null,
            viewEdit: false,
            proxy_dropdownFields: {text: 'provider_name', value: '_id'},
            proxy_services: [],
            services_dropdownFields: {text: 'service_name', value: '_id'},
            selected_provider: '',
            selected_service: '',
        };
    },
    methods: {
        initialize() {
            this.loadProxyProviders();
            this.loadPricingContracts();
        },
        closeEdit() {
            this.editPricingContract = null;
            this.viewEdit = false;
            this.loadPricingContracts();
        },
        getServiceByProvider() {
            axios.get(`${this.$root.serverUrl}/admin/proxy-services/provider/${this.selected_provider}`).then(
                (resp) => {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.proxy_services = resp && resp.data && resp.data.data ? resp.data.data : [];
                }
            );
        },
        loadProxyProviders() {
            axios.get(`${this.$root.serverUrl}/admin/proxy-providers`).then(
                function (resp) {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.proxy_providers = resp && resp.data && resp.data.data ? resp.data.data : [];
                }.bind(this)
            );
        },
        loadPricingContracts() {
            axios.get(`${this.$root.serverUrl}/admin/pricing-contracts`).then(
                (resp) => {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.dataSource = resp && resp.data && resp.data.data ? resp.data.data : [];
                }
            );
        },
        actionTemplate: function () {
            return {
                template: Vue.component("actionTemplate", {
                    template: `
                        <div class="d-flex" style="gap: 5px;">
                            <button
                                class="btn btn-sm btn-primary" @click="edit"
                            ><i class="fa fa-pencil" title="edit"></i></button>
                            <button class="btn btn-sm btn-danger"
                                    @click="remove"
                            ><i class="fa fa-trash" title="Delete"></i></button>
                        </div>
                    `,
                    data: function () {
                        return {
                            data: {},
                        };
                    },
                    methods: {
                        edit() {
                            Bus.$emit("editPricing", this.data);
                        },
                        remove: function () {
                            //Handle removing the item
                            swal({
                                title: "Remove",
                                text: "Are you sure?",
                                icon: "info",
                                buttons: ["No", "Yes"],
                            }).then((val) => {
                                if (!val) return;

                                var request = {
                                    apikey: this.$root.apikey,
                                    id: this.data._id,
                                };
                                // this.$root.preloader = true;

                                axios({
                                    url: `${this.$root.serverUrl}/admin/social/${this.data._id}`,
                                    data: request,
                                    responseType: "json",
                                    method: "DELETE",
                                })
                                    .then((resp) => {
                                        //Process the results
                                        this.$root.preloader = false;
                                        if (resp.data && !resp.data.error) {
                                            swal({
                                                title: "Removed",
                                                text: "Your request was completed successfully",
                                                icon: "success",
                                            }).then(
                                                function () {
                                                    Bus.$emit("delete");
                                                }.bind(this)
                                            );
                                        } else {
                                            //Handle errors
                                            swal({
                                                title: "Remove",
                                                text: resp.data.error
                                                    .message,
                                                icon: "error",
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        this.$root.preloader = false;
                                        this.errormsg =
                                            err.message ||
                                            "An unexpected error occurred";
                                        swal({
                                            title: "Error",
                                            text: this.errormsg,
                                            icon: "error",
                                        });
                                    });
                            });
                        },
                    },
                }),
            };
        },
        onProviderChange(event) {
            this.selected_provider = event.value;
            this.getServiceByProvider();
        },
        onServiceChange(event) {
            this.selected_service = event.value;
        },
        onSearch() {
            axios.get(`${this.$root.serverUrl}/admin/pricing-contracts?provider=${this.selected_provider}&service=${this.selected_service}`).then(
                (resp) => {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.dataSource = resp && resp.data && resp.data.data ? resp.data.data : [];
                }
            );
        }
    },
    mounted() {
        this.initialize();
        Bus.$on("delete", this.loadPricingContracts);
        Bus.$on("editPricing", (data) => {
            console.log(typeof data);
            this.editPricingContract = JSON.stringify(data);
            this.viewEdit = true;
        });
    },

    destroyed() {
        Bus.$off("delete");
        Bus.$off("editPricing");
    }
};

</script>

<template>
    <div class="container-fluid">
        <b-row class="d-flex mt-4 justify-content-sm-around">
            <b-col cols="4">
                <ejs-dropdownlist placeholder="Provider" :dataSource="proxy_providers" cssClass="e-outline"
                                  :value="selected_provider"
                                  floatLabelType="auto" :fields="proxy_dropdownFields"
                                  @change="onProviderChange"
                ></ejs-dropdownlist>
            </b-col>
            <b-col cols="4">
                <ejs-dropdownlist placeholder="Service" floatLabelType="auto" :dataSource="proxy_services" id="service"
                                  :fields="services_dropdownFields"
                                  :value="selected_service"
                                  cssClass="e-outline" @change="onServiceChange"></ejs-dropdownlist>
            </b-col>
            <b-col cols="2" class="mr-auto">
                <b-button variant="primary" @click="onSearch">
                    <i class="fa fa-search"></i>
                    Search
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="d-flex justify-content-end mb-1">
                <new-pricing-contract @close="this.initialize" :proxy_providers="proxy_providers"/>
            </b-col>
        </b-row>
        <ejs-grid :dataSource="dataSource" :allowPaging="true" :pageSettings="{pageSize: 5}" :allowSorting="true">
            <e-columns>
                <e-column field="provider_info.provider_name" headerText="Provider" width="150"></e-column>
                <e-column field="service_info.service_name" headerText="Service" width="150"></e-column>
                <e-column field="display_price" headerText="Price" width="150"></e-column>
                <e-column field="display_start_date" headerText="Start Date" width="150"></e-column>
                <e-column field="display_end_date" headerText="End Date" width="150"></e-column>
                <e-column headerText="Action" width="60" :template="actionTemplate"></e-column>
            </e-columns>
        </ejs-grid>

        <edit-pricing-contract v-if="editPricingContract" :pricing_contract="editPricingContract" :visible="viewEdit"
                               @close="closeEdit" />
    </div>
</template>

<style scoped>

</style>
