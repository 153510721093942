<template>
    <div class="col-12 mb-4 mt-3">

        <div id="accordion">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Email Configuration
                        </button>
                    </h5>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="input1">Recipient List for Email</label>
                                    <input-tag v-model="item.recipient"></input-tag>
                                </div>
                                <br> <!-- Add a line break for spacing -->
                                <div class="form-group">
                                    <label for="input2">Total Clicks for the Month </label>
                                    <input type="number" class="form-control" v-model="item.expectedMonthlyClicks" placeholder="Enter Number ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Click Monitoring Configuration
                        </button>
                    </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                    <div class="card-body">
                        <div class="row">
                            <clickCost :item="item" @addClickCost="handleAddClickCost"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Proxy Location Blocker
                        </button>
                    </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="input1">Blocked List</label>
                            <input-tag v-model="item.blocklist"></input-tag>
                            <label for="input1"> <span>Use a <span class="text-danger">full stop '.'</span> to separate State from City eg. <span class="text-danger"> 'Miami.FL' </span> then press enter </span></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--        <div class="row mt-4 justify-content-center">-->
<!--            <div class="col-10">-->
<!--                <b-form-select class="col-12 col-md-2 col-lg-2 mb-4" v-model="year" :options="years" @change="filter"  />-->

<!--                <b-card class="" header-tag="header">-->
<!--                    <template #header>-->
<!--                        <h6 class="mb-0 font-weight-bolder"> Forecasted Data </h6>-->

<!--                        <button class="btn btn-sm float-right" v-b-tooltip.hover.top title="Add Record" data-toggle="modal" data-target="#addModal" ><span class="fa fa-plus" title="Add Record"> Add</span></button>-->
<!--                    </template>-->

<!--                    <ejs-grid :dataSource='getData' :editSettings='editSettings' :toolbar='toolbar' :toolbarClick='toolbarClick' height='273px'>-->
<!--                        <e-columns>-->
<!--                            <e-column field='year' headerText='Year' textAlign='Left' :isPrimaryKey='false' editType='numericedit' width=100></e-column>-->
<!--                            <e-column field='month' headerText='Month' textAlign='Left' :isPrimaryKey='true' width=100></e-column>-->
<!--                            <e-column field='clicks' textAlign='Left' headerText='Forecasted Clicks' editType='numericedit' width=240></e-column>-->
<!--                            <e-column headerText='Site Impact (SI)' textAlign='Center' :columns='SI'></e-column>-->
<!--                        </e-columns>-->
<!--                    </ejs-grid>-->
<!--                </b-card>-->
<!--            </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; Modal &ndash;&gt;-->
<!--        <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="addModal" aria-hidden="true">-->
<!--            <div class="modal-dialog modal-dialog-centered" role="document">-->
<!--                <div class="modal-content">-->
<!--                    <div class="modal-header">-->
<!--                        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>-->
<!--                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--                            <span aria-hidden="true">&times;</span>-->
<!--                        </button>-->
<!--                    </div>-->
<!--                    <div class="modal-body">-->
<!--                        <div class="row">-->
<!--                            <div class="col-md-4">-->
<!--                                <div class="form-group" >-->
<!--                                    <label>Month</label>-->
<!--                                    <select v-model="monitoring.month" class="form-control">-->
<!--                                        <option value="">Month</option>-->
<!--                                        <option v-for="month in months" :key="month" :value="month">{{month}}</option>-->
<!--                                    </select>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                                <div class="form-group" >-->
<!--                                    <label>Forcasted Clicks</label>-->
<!--                                    <input type="number" class="form-control" v-model="monitoring.clicks" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                                <div class="form-group" >-->
<!--                                    <label>Total Clicks in Month</label>-->
<!--                                    <input type="number" class="form-control" v-model="monitoring.monthClicks" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="row">-->
<!--                            <div class="col-md-4">-->
<!--                                <div class="form-group" >-->
<!--                                    <label>ADR Estimated Amount</label>-->
<!--                                    <input type="number" class="form-control" v-model="monitoring.adAmount" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                                <div class="form-group" >-->
<!--                                    <label>Projected EOM Clicks</label>-->
<!--                                    <input type="number" class="form-control" v-model="monitoring.projectedClicks" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-md-4">-->
<!--                                <div class="form-group" >-->
<!--                                    <label>ADRTBN Possible %</label>-->
<!--                                    <input type="number" class="form-control" v-model="monitoring.possible" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="modal-footer">-->
<!--                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
<!--                        <button @click="saveForcast" type="button" class="btn btn-primary">Add record</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { GridPlugin, Page, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";
import clickCost from "./clickCost.vue";


Vue.use(GridPlugin);
export default {
    name: "click_monitoring",
    components: {clickCost},
    props: ["item"],
    data() {
      return {
          addRecord: false,
          recipient:'',
          monitoring: { month: "", adAmount: 0, monthClicks: 0, projectedClicks: 0, possible: 0, clicks: 0},
          year: new Date().getFullYear(),
          months: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December"
          ],
          editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' },
          toolbar: [
             'Edit', 'Delete', 'Update', 'Cancel'
            ],
          SI: [
              { field: 'monthClicks', headerText: 'Total Clicks in Month', textAlign: 'Left' },
              { field: 'adAmount', headerText: 'ADR Estimated Amount', textAlign: 'Left' },
              { field: 'projectedClicks', headerText: 'Projected EOM Clicks', textAlign: 'Left' },
              { field: 'possible', headerText: 'ADRTBN Possible %', textAlign: 'Left', editType: 'numericedit' },
          ]
      }
    },
    computed: {
        years() {
            const years = []
            const dateStart = moment().subtract(5, 'y')
            const dateEnd = moment()
            while (dateEnd.diff(dateStart, 'years') >= 0) {
                years.push({value: dateStart.format('YYYY'), text: dateStart.format('YYYY')})
                dateStart.add(1, 'year')
            }
            return years
        },
        getData() {
            let data = [];
            data = this.$store.state.settings ? this.$store.state.settings.forecastedData : data;
            return data;
        },
    },
    provide: {
        grid: [Page, Edit, Toolbar]
    },
    methods: {
        handleAddClickCost() {
            this.$emit( 'addClickSave' );
        },
        saveForcast() {
            let data = this.getData;
            for (let i = 0; i < data.length; i++) {
                if (!this.monitoring.month) {
                    swal({title: 'Missing Month', text: "Please select a month", icon: 'error'})
                    return
                }

                if (data[i].month === this.monitoring.month && data[i].year === this.monitoring.year) {
                    console.log(data[i], this.monitoring.year)
                    swal({title: 'Duplicate', text: "Duplicate Entry", icon: 'error'})
                    return
                }
            }

            this.monitoring.year = this.year;
            this.$store.state.settings.forecastedData = [...this.$store.state.settings.forecastedData, this.monitoring];
            window.$('#addModal').modal('hide')
            this.$emit( 'forecastUpdate' );
        },
        toolbarClick(args = ClickEventArgs) {
            switch (args.item.text) {
                /* tslint:disable */
                case 'Update':
                    this.$forceUpdate()
                    this.$emit( 'forecastUpdate' )
                break;
            }
        },
        filter(year) {
            this.$emit( 'filter', year)
        },
        load: function() {
            this.$refs.grid.ej2Instances.element.addEventListener('mouseup', function(e) {
                let instance = this.ej2_instances[0];
                if (e.target.classList.contains("e-rowcell")) {
                    if (instance.isEdit)
                        instance.endEdit();
                    let index = parseInt(e.target.getAttribute("Index"));
                    instance.selectRow(index);
                    instance.startEdit();
                }
            });
        }
    }
}
</script>

<style scoped>
/*@import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";*/
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
        margin: 1.75rem auto;
    }
}
</style>
