<template>
    <div class="col-12 pb-4 mb-2">

<!--    <div class="">-->
        <div class="row">
            <h4 class="col-12 p-3">Reset Counter</h4>
        </div>
        <reset_incidents class="card mb-4 p-3"  :user="user"/>
        <div class="row">
            <h4 class="col-12 p-3">Incidents Report</h4>
        </div>
        <incidents :user="user" class="card mb-4 p-3"/>
    </div>
<!--    </div>-->
</template>

<script>
import incidents from "@/components/reports/incidentsReport";
import reset_incidents from "@/components/settings/reset_incidents.vue";
import axios from "axios";
import swal from "sweetalert";

export default {
    props: {
        user: {
            type: Object,
            required: false
        }
    },
    components: {
        incidents,
        reset_incidents,

    },
    data: () => {
        return {
            text: '',
            reportData: [],
            errorCounter: null,
            counter:{counter:0},
            data:[]
        };
    },
    created() {
        //Register eventstream
        // if(!this.errorCounter) {
        //     this.errorCounter = new EventSource(`${this.$root.serverUrl}/static/incidentsCount`, {withCredentials: false});
        //
        //     this.errorCounter.onerror = (error) => {
        //         console.log(`incidentsCount.error`, error);
        //     }
        //     this.errorCounter.onopen = (args) => {
        //         console.log(`incidentsCount.open`, args);
        //     }
        //     this.errorCounter.onmessage = (event) => {
        //         //console.log(`eventWorkers.event`, event);
        //         try {
        //             const parsedData = JSON.parse(event.data);
        //             this.counter = Object.assign( parsedData);
        //             this.$forceUpdate();
        //         } catch (e) {
        //             console.log(`incidentsCount.catch: ${e.message}`);
        //         }
        //     };
        // }
    },
    computed: {
        getIncidents() {
            let data = this.counter.counter;
            return data;
        },
    },
    methods:{
        resetIncidents() {
            var request = {'apikey': this.$root.apikey};
            axios.post(`${this.$root.serverUrl}/admin/reports/incidentsUpdate`, {  counter: this.counter.counter,reason : this.text, user:this.user},{params:request}).then((resp) => {
                if(resp.data.error){
                    swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {

                    })
                }else{
                    swal({ title: 'Success', text: 'Reset Done.', icon: 'success' }).then(() => {

                    })
                }

            }).catch(() => {
                swal("Oops!", "There was an issue Resting", "error");
            })
        },




    }
};
</script>

<style>


</style>
