<template>
    <div class="col-12">
        <div class="container-fluid mb-5 mt-4">
            <div class="text-center">
                <h4>Automate crawler processes</h4>
            </div>

            <div class="row">
                <div class="col-12">
                    <div style="display: inline-block;">
                        <h4>Push</h4>
                        <p>Rules to set crawler settings to push traffic</p>
                    </div>
                    <div class="float-right">
                        <button class="btn btn-small btn-secondary" type="button" data-toggle='modal'
                                data-target='#myAutomationAuditModal'>Logs</button>
                    </div>
                </div>

            </div>

            <div class="card p-3 mb-3">
                <div class="">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Min Shown Rate %</label>
                            <input
                                type="number"
                                v-model="item.automation.push.min_shown_rate"
                                class="form-control"
                            />
                            <div class="form-text text-muted">Minimum show to push traffic</div>
                        </div>
                    </div>
                    <div class="row">
                        <h6 class="col-12">Morning</h6>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Start Time</label>
                                <div>
                                    <vue-timepicker input-class="form-control" input-width="100%" class="w-100" format="h:m a" v-model="item.automation.push.morning_start_time"></vue-timepicker>
                                </div>
                                <div class="form-text text-muted">Start time to set overnight traffic</div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>End Time</label>
                                <div>
                                    <vue-timepicker input-class="form-control" input-width="100%" class="w-100" format="h:m a" v-model="item.automation.push.morning_end_time"></vue-timepicker>
                                </div>
                                <div class="form-text text-muted">End time to unset overnight traffic</div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Min Need Limit</label>
                                <input
                                    type="text"
                                    v-model="item.automation.push.morning_min_need_limit"
                                    class="form-control"
                                />
                                <div class="form-text text-muted">
                                    Crawler minimum need limit
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Max Need Limit</label>
                                <input
                                    type="text"
                                    v-model="item.automation.push.morning_max_need_limit"
                                    class="form-control"
                                />
                                <div class="form-text text-muted">
                                    Crawler Maximum need limit
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Queue Services</label>
                                <input
                                    type="text"
                                    v-model="item.automation.push.morning_services"
                                    class="form-control"
                                />
                                <div class="form-text text-muted">
                                    Amount of queue services
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h6 class="col-12">Afternoon</h6>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Start Time</label>
                                <vue-timepicker input-class="form-control" input-width="100%" class="w-100" format="h:m a" v-model="item.automation.push.afternoon_start_time"></vue-timepicker>
                                <div class="form-text text-muted">Start time to set overnight traffic</div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>End Time</label>
                                <vue-timepicker input-class="form-control" input-width="100%" class="w-100" format="h:m a" v-model="item.automation.push.afternoon_end_time"></vue-timepicker>
                                <div class="form-text text-muted">End time to unset overnight traffic</div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Min Need Limit</label>
                                <input
                                    type="text"
                                    v-model="item.automation.push.afternoon_min_need_limit"
                                    class="form-control"
                                />
                                <div class="form-text text-muted">
                                    Crawler minimum need limit
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Max Need Limit</label>
                                <input
                                    type="text"
                                    v-model="item.automation.push.afternoon_max_need_limit"
                                    class="form-control"
                                />
                                <div class="form-text text-muted">
                                    Crawler Maximum need limit
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <div class="form-group">
                                <label>Queue Services</label>
                                <input
                                    type="text"
                                    v-model="item.automation.push.afternoon_services"
                                    class="form-control"
                                />
                                <div class="form-text text-muted">
                                    Amount of queue services
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <h4 class="col-12">Recovery</h4>
                <p class="col-12">Rules to set crawler settings to recover bad shown</p>
            </div>

            <div class="card p-3 mb-3">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Max Shown Rate %</label>
                            <input
                                type="number"
                                v-model="item.automation.recovery.max_shown_rate"
                                class="form-control"
                            />
                            <div class="form-text text-muted">Maximum show to recover traffic</div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Min Need Limit</label>
                            <input
                                type="text"
                                v-model="item.automation.recovery.min_need_limit"
                                class="form-control"
                            />
                            <div class="form-text text-muted">
                                Crawler minimum need limit
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Max Need Limit</label>
                            <input
                                type="text"
                                v-model="item.automation.recovery.max_need_limit"
                                class="form-control"
                            />
                            <div class="form-text text-muted">
                                Crawler Maximum need limit
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Queue Services</label>
                            <input
                                type="text"
                                v-model="item.automation.recovery.services"
                                class="form-control"
                            />
                            <div class="form-text text-muted">
                                Amount of queue services
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <h4 class="col-12">Overnight</h4>
                <p class="col-12">Rules to set crawler settings to overnight</p>
            </div>

            <div class="card p-3 mb-3">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Start Time</label>
                            <vue-timepicker input-class="form-control" input-width="100%" class="w-100" format="h:m a" v-model="item.automation.overnight.start_time"></vue-timepicker>
                            <div class="form-text text-muted">Start time to set overnight traffic</div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>End Time</label>
                            <vue-timepicker input-class="form-control" input-width="100%" class="w-100" format="h:m a" v-model="item.automation.overnight.end_time"></vue-timepicker>
                            <div class="form-text text-muted">End time to unset overnight traffic</div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Min Need Limit</label>
                            <input
                                type="text"
                                v-model="item.automation.overnight.min_need_limit"
                                class="form-control"
                            />
                            <div class="form-text text-muted">
                                Crawler minimum need limit
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Max Need Limit</label>
                            <input
                                type="text"
                                v-model="item.automation.overnight.max_need_limit"
                                class="form-control"
                            />
                            <div class="form-text text-muted">
                                Crawler Maximum need limit
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <div class="form-group">
                            <label>Queue Services</label>
                            <input
                                type="text"
                                v-model="item.automation.overnight.services"
                                class="form-control"
                            />
                            <div class="form-text text-muted">
                                Amount of queue services
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <h4 class="col-12">Shutdown Schedule</h4>
                <p class="col-12">Schedule when the system should be turned off.</p>
            </div>

            <div class="card p-3 mb-3">
                <div class="schedule-overview">
                    <ejs-appbar colorMode="Primary">
                        <span class="time e-icons e-time-zone"></span>
                        <span id="timezoneBtn" class="time ">UTC</span>
                        <span class="time e-icons e-clock"></span>
                        <span id="timeBtn" class="time current-time">{{liveTimeUpdate}}</span>
                        <div class="e-appbar-spacer"></div>
                        <ejs-button id="settingsBtn" iconCss="e-icons e-settings"
                                    cssClass="overview-toolbar-settings e-inherit" iconPosition="Top"
                                    v-on:click.native="onSettingsClick"></ejs-button>
                    </ejs-appbar>
                    <ejs-toolbar id="toolbarOptions" height="70px" overflowMode="Scrollable" scrollStep="100"  cssClass= 'overview-toolbar'
                                 :created="onToolbarCreated" :clicked="onToolbarItemClicked">
                        <e-items>
                            <e-item prefixIcon='e-icons e-plus' tooltipText='New Event' text='New Event' tabindex="0">
                            </e-item>
                            <e-item prefixIcon='e-icons e-repeat'
                                    tooltipText='New Recurring Event' text='New Recurring Event' tabindex="0"></e-item>
                            <e-item type='Separator'></e-item>
                            <e-item prefixIcon='e-icons e-day' tooltipText='Day' text='Day' tabindex="0"></e-item>
                            <e-item prefixIcon='e-icons e-week' tooltipText='Week' text='Week' tabindex="0"></e-item>
                            <e-item prefixIcon='e-icons e-month' tooltipText='Month' text='Month' tabindex="0">
                            </e-item>
                            <e-item prefixIcon='e-icons e-month' tooltipText='Year' text='Year' tabindex="0"></e-item>
                            <e-item tooltipText="Timeline Views" :template="'timelineTemplate'"></e-item>
                            <template v-slot:timelineTemplate>
                                <div class="template">
                                    <div class="icon-child">
                                        <ejs-checkbox id="timeline_views" :checked="false" :created="onTimelineCreated" :change="onTimelineViewChange"></ejs-checkbox>
                                    </div>
                                    <div class="text-child">Timeline Views</div>
                                </div>
                            </template>
                            <e-item type='Separator'></e-item>
                            <e-item tooltipText="Time Slots" :template="'gridlineTemplate'"></e-item>
                            <template v-slot:gridlineTemplate>
                                <div class="template">
                                    <div class="icon-child">
                                        <ejs-checkbox id="timeSlot" :checked="true" :created="onTimeSlotCreated" :change="onTimeSlotChange"></ejs-checkbox>
                                    </div>
                                    <div class="text-child">Time Slots</div>
                                </div>
                            </template>
                            <e-item tooltipText="Auto Fit Rows" :template="'autoHeightTemplate'"></e-item>
                            <template v-slot:autoHeightTemplate>
                                <div class="template">
                                    <div class="icon-child">
                                        <ejs-checkbox id="row_auto_height" :checked="false" :created="onRowAutoHeightCreated" :change="onRowAutoHeightChange"></ejs-checkbox>
                                    </div>
                                    <div class="text-child">Auto Fit Rows</div>
                                </div>
                            </template>
                        </e-items>
                    </ejs-toolbar>
                    <div class="overview-content">
                        <div class="left-panel">
                            <div class="overview-scheduler">
                                <ejs-schedule id='scheduler' ref="scheduleObj"  height="100%" cssClass='schedule-overview' :actionBegin="onActionBegin"
                                              :currentView='currentView' :eventSettings='eventSettings'
                                              :popupOpen="onPopupOpen" :actionComplete="onActionComplete" :navigating="onNavigating">
                                    <e-views>
                                        <e-view option="Day"></e-view>
                                        <e-view option="Week"></e-view>
                                        <e-view option="WorkWeek"></e-view>
                                        <e-view option="Month"></e-view>
                                        <e-view option="Year"></e-view>
                                        <e-view option="Agenda"></e-view>
                                        <e-view option="TimelineDay"></e-view>
                                        <e-view option="TimelineWeek"></e-view>
                                        <e-view option="TimelineWorkWeek"></e-view>
                                        <e-view option="TimelineMonth"></e-view>
                                        <e-view option="TimelineYear"></e-view>
                                    </e-views>
                                    <e-resources>
                                        <e-resource field="CalendarId" title="Calendars" :dataSource="resourceDataSource" :query='resourceQuery' :allowMultiple="allowMultiple" name="Calendars"
                                                    textField="CalendarText" idField="CalendarId" colorField='CalendarColor'>
                                        </e-resource>
                                    </e-resources>
                                </ejs-schedule>
                                <ejs-contextmenu id="overviewContextMenu" ref="menuObj" cssClass="schedule-context-menu" target=".e-schedule" :items="menuItems" :beforeOpen="onContextMenuBeforeOpen" :select="onMenuItemSelect" ></ejs-contextmenu>
                            </div>
                        </div>
                        <div class="right-panel hide">
                            <div class="control-panel e-css">
                                <div class="col-row">
                                    <div class="col-left">
                                        <label style="line-height: 34px; margin: 0;">First Day of Week</label>
                                    </div>
                                    <div class="col-right">
                                        <ejs-dropdownlist id='weekFirstDay' :dataSource='weekDays'
                                                          :change='onWeekDayChange' :value='firstDayOfWeek' :fields='fields'></ejs-dropdownlist>
                                    </div>
                                </div>
                                <div class="col-row">
                                    <div class="col-left">
                                        <label style="line-height: 34px; margin: 0;">Timezone</label>
                                    </div>
                                    <div class="col-right">
                                        <ejs-dropdownlist id='timezone' :dataSource='timezoneData'
                                                          :change='onTimezoneChange' value='Etc/GMT' :fields='fields'
                                                          popupHeight='150px'></ejs-dropdownlist>
                                    </div>
                                </div>
                                <div class="col-row">
                                    <div class="col-left">
                                        <label style="line-height: 34px; margin: 0;">Day Start Hour</label>
                                    </div>
                                    <div class="col-right">
                                        <ejs-timepicker id='dayStartHour' :showClearButton='false'
                                                        :value='dayStartHourValue' :change='onDayStartHourChange'>
                                        </ejs-timepicker>
                                    </div>
                                </div>
                                <div class="col-row">
                                    <div class="col-left">
                                        <label style="line-height: 34px; margin: 0;">Day End Hour</label>
                                    </div>
                                    <div class="col-right">
                                        <ejs-timepicker id='dayEndHour' :showClearButton='false'
                                                        :value='dayEndHourValue' :change='onDayEndHourChange'>
                                        </ejs-timepicker>
                                    </div>
                                </div>
                                <div class="col-row">
                                    <div class="col-left">
                                        <label style="line-height: 34px; margin: 0;">Slot Duration</label>
                                    </div>
                                    <div class="col-right">
                                        <ejs-dropdownlist id='slotDuration' :dataSource='timeSlotDuration'
                                                          :change='onTimescaleDurationChange' :value='timeSlotDurationValue'
                                                          popupHeight='150px'></ejs-dropdownlist>
                                    </div>
                                </div>
                                <div class="col-row">
                                    <div class="col-left">
                                        <label style="line-height: 34px; margin: 0;">Slot Interval</label>
                                    </div>
                                    <div class="col-right">
                                        <ejs-dropdownlist id='slotInterval' :dataSource='timeSlotCount'
                                                          :change='onTimescaleIntervalChange' :value='timeSlotCountValue'
                                                          popupHeight='150px'></ejs-dropdownlist>
                                    </div>
                                </div>
                                <div class="col-row">
                                    <div class="col-left">
                                        <label style="line-height: 34px; margin: 0;">Time Format</label>
                                    </div>
                                    <div class="col-right">
                                        <ejs-dropdownlist id='timeFormat' :dataSource='timeFormatdata'
                                                          :change='onchangeTimeFormat' :value='timeFormatValue' :fields='fields'
                                                          popupHeight='150px'></ejs-dropdownlist>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <audit></audit>
        <ejs-toast ref='elementRef' id='element' title='' content='' :position='position'></ejs-toast>
    </div>
</template>

<script>
import moment from "moment";
import Audit from "@/components/settings/automation_audit";
import { addClass, extend, removeClass, closest, remove, isNullOrUndefined, Internationalization } from '@syncfusion/ej2-base';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';

import { TimePickerComponent as EjsTimepicker } from '@syncfusion/ej2-vue-calendars';

import { ScheduleComponent, ViewDirective, ViewsDirective, ResourceDirective, ResourcesDirective, Day, Week, WorkWeek, Month, Agenda, TimelineMonth, Year, TimelineViews,TimelineYear, Resize, DragAndDrop, Print, ExcelExport,ICalendarImport, ICalendarExport } from "@syncfusion/ej2-vue-schedule";

import { ToolbarComponent, ItemsDirective, ItemDirective } from "@syncfusion/ej2-vue-navigations";
import { TimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { AppBarComponent, ContextMenuComponent } from "@syncfusion/ej2-vue-navigations";
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-vue-buttons';
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
import Vue from "vue";
import store from "@/store";
import ScheduleBus from "@/busses/scheduleBus";
import axios from "axios";
import {ToastPlugin} from "@syncfusion/ej2-vue-notifications";
MultiSelect.Inject(CheckBoxSelection);
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

Vue.use(ToastPlugin);
var liveTimeInterval;

export default {
    name: "automation",
    components: {
        VueTimepicker,
        EjsTimepicker,
        Audit,
        'ejs-schedule': ScheduleComponent,
        'e-view': ViewDirective,
        'e-views': ViewsDirective,
        'e-resource': ResourceDirective,
        'e-resources': ResourcesDirective,
        'ejs-appbar': AppBarComponent,
        'ejs-button': ButtonComponent,
        'ejs-toolbar': ToolbarComponent,
        'e-items': ItemsDirective,
        'e-item': ItemDirective,
        'ejs-timepicker': TimePickerComponent,
        'ejs-checkbox': CheckBoxComponent,
        'ejs-contextmenu': ContextMenuComponent,
        'ejs-dropdownlist': DropDownListComponent
    },
    provide: {
        schedule: [Day, Week, WorkWeek, Month, Agenda, Resize, DragAndDrop,TimelineMonth, TimelineViews, Year, TimelineYear, Print, ExcelExport,ICalendarImport, ICalendarExport]
    },
    beforeDestroy() {
        if (liveTimeInterval) {
            clearInterval(liveTimeInterval);
        }
    },
    props: {
        item: {
            type: Object,
            default: ()=>{
                return { automation: { push: {}, recovery: {}, overnight: {} } }
            }
        },
        save:{
            type: Object,
            default:()=>{return { }}
        }
    },
    created() {
        if (!this.item.automation) this.item.automation = { push: {}, recovery: {}, overnight: {} };
        this.loadRecord({start: moment().startOf("week").toDate(), end: moment().endOf('week').toDate()});

        let server_timezone_diff = -5.0;
        let client_timezone_diff = new Date().getTimezoneOffset();

        this.serverTimezoneOffset = server_timezone_diff + client_timezone_diff;
    },
    mounted() {
        document.querySelector(".e-schedule").ej2_instances[0].workHours.start = this.intl.formatDate(this.workStartHourValue, { skeleton: 'Hm' });
        document.querySelector(".e-schedule").ej2_instances[0].workHours.end = this.intl.formatDate(this.workEndHourValue, { skeleton: 'Hm' });
    },
    data: function () {
        return {
            serverTimezoneOffset: -5,
            intl: new Internationalization(),
            old: null,
            scheduleObj: null,
            create: false,
            models: [],
            position: {X: 'Right', Y: 'Bottom'},
            pageSettings: { pageSize: 50, pageSizes: [50, 100, 500] },
            showFileList: false,
            multiple:  false,
            calendarFields: { text: 'CalendarText', value: 'CalendarId' },
            firstDayOfWeek: 0,
            dayStartHourValue: new Date(new Date().setHours(0, 0, 0)),
            dayEndHourValue: new Date(new Date().setHours(23, 59, 59)),
            workStartHourValue: new Date(new Date().setHours(0, 0, 0)),
            workEndHourValue:  new Date(new Date().setHours(23, 59, 59)),
            timeSlotDuration: [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360],
            timeSlotCount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            timeSlotDurationValue: 60,
            timeSlotCountValue: 2,
            fields: { text: 'text', value: 'value' },
            timezoneData: [
                { text: 'UTC -12:00', value: 'Etc/GMT+12' },
                { text: 'UTC -11:00', value: 'Etc/GMT+11' },
                { text: 'UTC -10:00', value: 'Etc/GMT+10' },
                { text: 'UTC -09:00', value: 'Etc/GMT+9' },
                { text: 'UTC -08:00', value: 'Etc/GMT+8' },
                { text: 'UTC -07:00', value: 'Etc/GMT+7' },
                { text: 'UTC -06:00', value: 'Etc/GMT+6' },
                { text: 'UTC -05:00', value: 'Etc/GMT+5' },
                { text: 'UTC -04:00', value: 'Etc/GMT+4' },
                { text: 'UTC -03:00', value: 'Etc/GMT+3' },
                { text: 'UTC -02:00', value: 'Etc/GMT+2' },
                { text: 'UTC -01:00', value: 'Etc/GMT+1' },
                { text: 'UTC +00:00', value: 'Etc/GMT' },
                { text: 'UTC +01:00', value: 'Etc/GMT-1' },
                { text: 'UTC +02:00', value: 'Etc/GMT-2' },
                { text: 'UTC +03:00', value: 'Etc/GMT-3' },
                { text: 'UTC +04:00', value: 'Etc/GMT-4' },
                { text: 'UTC +05:00', value: 'Etc/GMT-5' },
                { text: 'UTC +05:30', value: 'Asia/Calcutta' },
                { text: 'UTC +06:00', value: 'Etc/GMT-6' },
                { text: 'UTC +07:00', value: 'Etc/GMT-7' },
                { text: 'UTC +08:00', value: 'Etc/GMT-8' },
                { text: 'UTC +09:00', value: 'Etc/GMT-9' },
                { text: 'UTC +10:00', value: 'Etc/GMT-10' },
                { text: 'UTC +11:00', value: 'Etc/GMT-11' },
                { text: 'UTC +12:00', value: 'Etc/GMT-12' },
                { text: 'UTC +13:00', value: 'Etc/GMT-13' },
                { text: 'UTC +14:00', value: 'Etc/GMT-14' }
            ],
            timeFormatdata: [
                { text: '12 hours', value: "hh:mm a" },
                { text: '24 hours', value: "HH:mm" }
            ],
            timeFormatValue: "hh:mm a",
            checkboxMode: 'CheckBox',
            workDays: [0, 1, 2, 3, 4, 5, 6],
            weekDays: [
                { text: 'Sunday', value: 0 },
                { text: 'Monday', value: 1 },
                { text: 'Tuesday', value: 2 },
                { text: 'Wednesday', value: 3 },
                { text: 'Thursday', value: 4 },
                { text: 'Friday', value: 5 },
                { text: 'Saturday', value: 6 }
            ],
            calendarsValue: [1],
            liveTimeUpdate: new Date().toLocaleTimeString('en-US', { timeZone: 'UTC' }),
            exportItems: [
                { text: 'iCalendar', iconCss: 'e-icons e-export' },
                { text: 'Excel', iconCss: 'e-icons e-export-excel' }
            ],
            eventSettings: {
                dataSource: [],
                fields: {
                    subject: { name: 'Subject', default: 'STOP' },
                    startTime: { name: 'StartTime' },
                    endTime: { name: 'EndTime' },
                    allDay: { name: 'IsAllDay', default: false },
                }
            },
            currentView: 'Week',
            resourceDataSource:  [
                { CalendarText: 'Calendar', CalendarId: 1, CalendarColor: '#c43081' }
            ],
            resourceQuery: new Query().where('CalendarId', 'equal', 1),
            allowMultiple: true,
            menuItems: [
                {
                    text: 'New Event',
                    iconCss: 'e-icons e-plus',
                    id: 'Add'
                }, {
                    text: 'New Recurring Event',
                    iconCss: 'e-icons e-repeat',
                    id: 'AddRecurrence'
                }, {
                    text: 'Today',
                    iconCss: 'e-icons e-timeline-today',
                    id: 'Today'
                }, {
                    text: 'Edit Event',
                    iconCss: 'e-icons e-edit',
                    id: 'Save'
                }, {
                    text: 'Edit Event',
                    id: 'EditRecurrenceEvent',
                    iconCss: 'e-icons e-edit',
                    items: [{
                        text: 'Edit Occurrence',
                        id: 'EditOccurrence'
                    }, {
                        text: 'Edit Series',
                        id: 'EditSeries'
                    }]
                }, {
                    text: 'Delete Event',
                    iconCss: 'e-icons e-trash',
                    id: 'Delete'
                }, {
                    text: 'Delete Event',
                    id: 'DeleteRecurrenceEvent',
                    iconCss: 'e-icons e-trash',
                    items: [{
                        text: 'Delete Occurrence',
                        id: 'DeleteOccurrence'
                    }, {
                        text: 'Delete Series',
                        id: 'DeleteSeries'
                    }]
                }
            ],
            editorTemplate:function () {
                return {
                    template: Vue.component("Content", {
                        template: `
                            <table class="schedule-custom-event-editor" width="100%" cellpadding="5">
                                <tbody>
                                    <tr>
                                        <td class="e-textlabel">Summary</td>
                                        <td colspan="4">
                                            <input id="Subject" class="e-field e-input" type="text" value="" name="Subject" style="width: 100%" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="e-textlabel">Status</td>
                                        <td colspan="4">
                                            <ejs-dropdownlist id='EventType' name="EventType" class="e-field" placeholder= 'Choose status' :dataSource='eventData' >
                                            </ejs-dropdownlist>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="e-textlabel">From</td>
                                        <td colspan="4">
                                            <ejs-datetimepicker id="StartTime" class="e-field" name="StartTime"></ejs-datetimepicker>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="e-textlabel">To</td>
                                        <td colspan="4">
                                            <ejs-datetimepicker id="EndTime" class="e-field" name="EndTime" ></ejs-datetimepicker>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="e-textlabel">Reason</td>
                                        <td colspan="4">
                                            <textarea id="Description" class="e-field e-input" name="Description" rows="3" cols="50" style="width: 100%; height: 60px !important; resize: vertical"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        `,
                        store: store,
                        mounted() {
                            ScheduleBus.$emit('defaultEditorValues', {
                                lock: this.data.generated,
                                redistribute: false
                            })
                        },
                        data() {
                            return {
                                eventData:  ['New', 'Requested', 'Confirmed'],
                                data: {}
                            }
                        },
                        methods:{

                            RedistributeClicks: function(e){
                                ScheduleBus.$emit('redistributeChange',{
                                    value: e.checked
                                });
                            },
                            LockClicks: function(e){
                                ScheduleBus.$emit('lockChange',{
                                    value: e.checked
                                });
                            }
                        }


                    })
                };
            },
        };
    },
    methods: {
        async loadRecord(dateRange) {
            let filters = {...dateRange};

            try {
                const resp = await axios.get(`${this.$root.serverUrl}/admin/stop_schedule`, {
                    params: {
                        filters
                    }
                })

                if (resp.data && !resp.data.error) {
                    this.$refs.scheduleObj.ej2Instances.eventSettings.dataSource = extend([], resp.data.data, null, true);
                    document.getElementById('scheduler').ej2_instances[0].refreshEvents();
                } else {
                    //Handle errors
                    const errormsg = (resp.data && resp.data.error) ? resp.data.error : "An unexpected error occurred"
                    this.$refs.elementRef.show({title: 'Error !', content: errormsg, cssClass: 'e-toast-danger'});
                }
            } catch (err) {
                this.errormsg = err.message || "An unexpected error occurred"
                this.$refs.elementRef.show({title: 'Error !', content: this.errormsg, cssClass: 'e-toast-danger'});
            }
        },
        onNavigating: function (e) {
            if (e.action === 'view') {
                this.currentView = e.currentView
            }

            let dateRange = {}
            if (e.action === 'date') {
                dateRange = {
                    start: moment(e.currentDate).startOf(this.currentView.toLowerCase()).toDate(),
                    end: moment(e.currentDate).endOf(this.currentView.toLowerCase()).toDate()
                }
            } else if (e.action === 'view') {
                dateRange = {
                    start: moment(e.currentDate).startOf(this.currentView.toLowerCase()).toDate(),
                    end: moment(e.currentDate).endOf(this.currentView.toLowerCase()).toDate()
                }

            }
            this.loadRecord(dateRange);
        },
        onPopupOpen: function (args) {
            if (args.type === 'QuickInfo') {
                let name = args.element.querySelector('input[name=Subject]');
                if (name) name.setAttribute('disabled', true)
            }
            if (args.type === 'Editor') {
                let title = args.element.querySelector('.e-title-location-row');
                if (title) title.style.display = 'none';

                let description = args.element.querySelector('.e-description-row');
                if (description) description.style.display = 'none';

                let resources = args.element.querySelector('.e-resources-row');
                if (resources) resources.style.display = 'none';
            }
        },
        parseRule(ruleString) {
            const ruleObject = {};

            const ruleParts = ruleString.split(';');

            for (const part of ruleParts) {
                const [key, value] = part.split('=');
                ruleObject[key] = value;
            }

            return ruleObject;
        },
        toDate(dateString) {
            const year = parseInt(dateString.substring(0, 4), 10);
            const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are 0-based in JavaScript
            const day = parseInt(dateString.substring(6, 8), 10);
            const hour = parseInt(dateString.substring(9, 11), 10);
            const minute = parseInt(dateString.substring(11, 13), 10);
            const second = parseInt(dateString.substring(13, 15), 10);

            return moment(Date.UTC(year, month, day, hour, minute, second));
        },
        parseRecurrenceRule(data) {
            let parsed = this.parseRule(data.RecurrenceRule);

            data.end = moment(data.EndTime).add(100, 'year').endOf('day').toDate();

            let interval = Number(parsed["INTERVAL"]);

            let counter = "day";
            if (parsed["FREQ"] === 'WEEKLY') counter = 'week';
            if (parsed["FREQ"] === 'MONTHLY') counter = 'month';
            if (parsed["FREQ"] === 'YEARLY') counter = 'year';

            if (parsed["COUNT"]) {
                interval = Number(parsed["COUNT"]) * interval;
                data.end = moment().add(interval, counter).endOf('day').toDate();
            }

            if (parsed["UNTIL"])  data.end = this.toDate(parsed["UNTIL"]).endOf('day').toDate();
            return data;
        },
        async addRecord(data) {

            data.start = moment(data.StartTime).startOf('day').toDate();
            data.end = moment(data.EndTime).endOf('day').toDate();

            if (data.RecurrenceRule) data = this.parseRecurrenceRule(data);

            try {
                const resp = await axios({
                    url: `${this.$root.serverUrl}/admin/stop_schedule`,
                    data: data,
                    responseType: 'json',
                    method: 'POST'
                });

                if (resp.data && !resp.data.error) {
                    this.$refs.elementRef.show({
                        title: 'Success !',
                        content: 'Your changes were saved successfully',
                        cssClass: 'e-toast-success'
                    });
                } else {
                    //Handle errors
                    const errormsg = (resp.data && resp.data.error) ? resp.data.error : "An unexpected error occurred"
                    this.$refs.elementRef.show({title: 'Error !', content: errormsg, cssClass: 'e-toast-danger'});
                    this.$refs.scheduleObj.ej2Instances.deleteEvent(data, 'Delete');
                }
            } catch (err) {
                const errormsg = err.message || "An unexpected error occurred"
                this.$refs.elementRef.show({title: 'Error !', content: errormsg, cssClass: 'e-toast-danger'});
                this.$refs.scheduleObj.ej2Instances.deleteEvent(data, 'Delete');
            }
            document.getElementById('scheduler').ej2_instances[0].refreshEvents();
        },
        async deleteRecord(data) {
            console.log(data)
            try {
                const resp = await axios({
                    url: `${this.$root.serverUrl}/admin/stop_schedule/${data._id}`,
                    responseType: 'json',
                    method: 'DELETE'
                });

                if (resp.data && !resp.data.error) {
                    this.$refs.elementRef.show({
                        title: 'Success !',
                        content: 'Event successfully deleted',
                        cssClass: 'e-toast-success'
                    });
                } else {
                    //Handle errors
                    const errormsg = (resp.data && resp.data.error) ? resp.data.error : "An unexpected error occurred"
                    this.$refs.elementRef.show({title: 'Error !', content: errormsg, cssClass: 'e-toast-danger'});
                    this.$refs.scheduleObj.ej2Instances.addEvent(data);
                }
            } catch (err) {
                console.log(err)
                const errormsg = err.message || "An unexpected error occurred"
                this.$refs.elementRef.show({title: 'Error !', content: errormsg, cssClass: 'e-toast-danger'});
                this.$refs.scheduleObj.ej2Instances.addEvent(data);
            }
            document.getElementById('scheduler').ej2_instances[0].refreshEvents();
        },
        async updateRecord(data) {
            if (data.RecurrenceRule) data = this.parseRecurrenceRule(data)

            try {
                const resp = await axios({
                    url: `${this.$root.serverUrl}/admin/stop_schedule/${data._id}`,
                    data: data,
                    responseType: 'json',
                    method: 'PUT'
                });

                if (resp.data && !resp.data.error) {
                    this.$refs.elementRef.show({
                        title: 'Success !',
                        content: 'Your changes were saved successfully',
                        cssClass: 'e-toast-success'
                    });
                } else {
                    //Handle errors
                    const errormsg = (resp.data && resp.data.error) ? resp.data.error : "An unexpected error occurred"
                    this.$refs.elementRef.show({title: 'Error !', content: errormsg, cssClass: 'e-toast-danger'});
                    this.$refs.scheduleObj.ej2Instances.saveEvent(this.old);
                }
            } catch (err) {
                const errormsg = err.message || "An unexpected error occurred"
                this.$refs.elementRef.show({title: 'Error !', content: errormsg, cssClass: 'e-toast-danger'});
                this.$refs.scheduleObj.ej2Instances.saveEvent(this.old);
            }
            document.getElementById('scheduler').ej2_instances[0].refreshEvents();
        },
        onActionBegin(args) {
            if (args.requestType === 'eventChange') this.old = this.$refs.scheduleObj.ej2Instances.activeEventData.event;
        },
        onActionComplete: function (e) {
            if (e.requestType === "eventCreated") {
                this.addRecord(e.addedRecords[0]);
            } else if (e.requestType === "eventRemoved") {
                console.log(e)
               if (e.deletedRecords.length) this.deleteRecord(e.deletedRecords[0]);
               if (e.changedRecords.length) this.updateRecord(e.changedRecords[0]);
            } else if (e.requestType === "eventChanged") {
                this.updateRecord(e.changedRecords[0]);
            }
        },
        getEventData: function() {
            let scheduleObj = this.$refs.scheduleObj.ej2Instances;
            const date = scheduleObj.selectedDate;
            return {
                Id: scheduleObj.getEventMaxID(),
                Subject: '',
                StartTime: new Date(date.getFullYear(), date.getMonth(), date.getDate(), new Date().getHours(), 0, 0),
                EndTime: new Date(date.getFullYear(), date.getMonth(), date.getDate(), new Date().getHours() + 1, 0, 0),
                Location: '',
                Description: '',
                IsAllDay: false,
                CalendarId: 1
            };

        },
        onWeekDayChange: function(args) {
            let scheduleObj = this.$refs.scheduleObj.ej2Instances;
            scheduleObj.firstDayOfWeek = args.value;
        },
        onToolbarItemClicked: function(args) {
            let scheduleObj = this.$refs.scheduleObj.ej2Instances;
            let timelineSwitch = document.getElementById('timeline_views').ej2_instances[0];
            let isTimelineView = timelineSwitch.checked;
            let recEventData;
            let eventData;

            switch (args.item.text) {
                case 'Day':
                    scheduleObj.currentView = isTimelineView ? 'TimelineDay' : 'Day';
                    break;
                case 'Week':
                    scheduleObj.currentView = isTimelineView ? 'TimelineWeek' : 'Week';
                    break;
                case 'Month':
                    scheduleObj.currentView = isTimelineView ? 'TimelineMonth' : 'Month';
                    break;
                case 'Year':
                    scheduleObj.currentView = isTimelineView ? 'TimelineYear' : 'Year';
                    break;
                case 'New Event':
                    eventData = this.getEventData();
                    scheduleObj.openEditor(eventData, 'Add', true);
                    break;
                case 'New Recurring Event':
                    recEventData = this.getEventData();
                    scheduleObj.openEditor(recEventData, 'Add', true, 1);
                    break;
            }
        },
        onTimelineCreated: function() {
            document.getElementById('timeline_views').setAttribute('tabindex', '0');
        },
        onTimelineViewChange: function(args) {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            let isTimelineView = args.checked;
            switch (scheduleObj.currentView) {
                case 'Day':
                case 'TimelineDay':
                    scheduleObj.currentView = isTimelineView ? 'TimelineDay' : 'Day';
                    break;
                case 'Week':
                case 'TimelineWeek':
                    scheduleObj.currentView = isTimelineView ? 'TimelineWeek' : 'Week';
                    break;
                case 'Month':
                case 'TimelineMonth':
                    scheduleObj.currentView = isTimelineView ? 'TimelineMonth' : 'Month';
                    break;
                case 'Year':
                case 'TimelineYear':
                    scheduleObj.currentView = isTimelineView ? 'TimelineYear' : 'Year';
                    break;
            }
        },
        onTimeSlotCreated: function() {
            document.getElementById('timeSlot').setAttribute('tabindex', '0');
        },
        onTimeSlotChange: function(args) {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            scheduleObj.timeScale.enable = args.checked;
        },
        onToolbarCreated: function() {
            let scheduleObj = this.$refs.scheduleObj.ej2Instances;
            liveTimeInterval = setInterval(() => { this.updateLiveTime(scheduleObj ? scheduleObj.timezone : 'UTC'); }, 1000);
        },
        onRowAutoHeightCreated: function() {
            document.getElementById('row_auto_height').setAttribute('tabindex', '0');
        },
        onRowAutoHeightChange: function(args) {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            scheduleObj.rowAutoHeight = args.checked;
        },
        updateLiveTime: function(timezone = 'UTC') {
            let scheduleObj = this.$refs.scheduleObj.ej2Instances;
            if (scheduleObj.isAdaptive) {
                this.liveTimeUpdate = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZone: timezone });
            }
            else {
                this.liveTimeUpdate = new Date().toLocaleTimeString('en-US', { timeZone: timezone });
            }
        },
        onTimezoneChange(args) {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            scheduleObj.timezone = args.value;
            this.updateLiveTime(scheduleObj.timezone);
            document.querySelector('.schedule-overview #timezoneBtn').innerHTML =
                '<span class="e-btn-icon e-icons e-time-zone e-icon-left"></span>' + args.itemData.text;
        },
        onDayStartHourChange: function(args) {
            if (!isNullOrUndefined(args.value)) {
                let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
                scheduleObj.startHour = this.intl.formatDate(args.value, { skeleton: 'Hm' });
            }
        },
        onDayEndHourChange: function(args) {
            if (!isNullOrUndefined(args.value)) {
                let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
                scheduleObj.endHour = this.intl.formatDate(args.value, { skeleton: 'Hm' });
            }
        },
        onTimescaleDurationChange: function(args) {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            scheduleObj.timeScale.interval = args.value;
        },
        onTimescaleIntervalChange: function(args) {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            scheduleObj.timeScale.slotCount = args.value;
        },
        onchangeTimeFormat: function(args) {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            scheduleObj.timeFormat = args.value;
        },
        onResourceChange(args) {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            let resourcePredicate;
            for (const value of args.value) {
                if (resourcePredicate) {
                    resourcePredicate = resourcePredicate.or(new Predicate('CalendarId', 'equal', value));
                } else {
                    resourcePredicate = new Predicate('CalendarId', 'equal', value);
                }
            }
            scheduleObj.resources[0].query = resourcePredicate ? new Query().where(resourcePredicate) :
                new Query().where('CalendarId', 'equal', 1);
        },
        onSettingsClick: function() {
            let scheduleObj = document.getElementById('scheduler').ej2_instances[0];
            const settingsPanel = document.querySelector('.overview-content .right-panel');
            if (settingsPanel.classList.contains('hide')) {
                removeClass([settingsPanel], 'hide');
                this.$refs.resourcesObj.ej2Instances.refresh();
            } else {
                addClass([settingsPanel], 'hide');
            }
            scheduleObj.refreshEvents();
        },
        onContextMenuBeforeOpen: function(args) {
            let newEventElement = document.querySelector('.e-new-event');
            if (newEventElement) {
                remove(newEventElement);
                removeClass([document.querySelector('.e-selected-cell')], 'e-selected-cell');
            }
            let scheduleObj = this.$refs.scheduleObj.ej2Instances;
            scheduleObj.closeQuickInfoPopup();
            let targetElement = args.event.target;
            if (closest(targetElement, '.e-contextmenu')) {
                return;
            }
            let menuObj = this.$refs.menuObj;
            this.selectedTarget = closest(targetElement, '.e-appointment,.e-work-cells,' +
                '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells');
            if (isNullOrUndefined(this.selectedTarget)) {
                args.cancel = true;
                return;
            }
            if (this.selectedTarget.classList.contains('e-appointment')) {
                let eventObj= scheduleObj.getEventDetails(this.selectedTarget);
                if (eventObj.RecurrenceRule) {
                    menuObj.showItems(['EditRecurrenceEvent', 'DeleteRecurrenceEvent'], true);
                    menuObj.hideItems(['Add', 'AddRecurrence', 'Today', 'Save', 'Delete'], true);
                } else {
                    menuObj.showItems(['Save', 'Delete'], true);
                    menuObj.hideItems(['Add', 'AddRecurrence', 'Today', 'EditRecurrenceEvent', 'DeleteRecurrenceEvent'], true);
                }
                return;
            } else if ((this.selectedTarget.classList.contains('e-work-cells') || this.selectedTarget.classList.contains('e-all-day-cells')) &&
                !this.selectedTarget.classList.contains('e-selected-cell')) {
                this.selectedTarget.setAttribute('aria-selected', 'true');
                this.selectedTarget.classList.add('e-selected-cell');
                removeClass([].slice.call(scheduleObj.element.querySelectorAll('.e-selected-cell')), 'e-selected-cell');
            }
            menuObj.hideItems(['Save', 'Delete', 'EditRecurrenceEvent', 'DeleteRecurrenceEvent'], true);
            menuObj.showItems(['Add', 'AddRecurrence', 'Today'], true);
        },
        onMenuItemSelect: function(args) {
            let scheduleObj = this.$refs.scheduleObj.ej2Instances;
            let selectedMenuItem = args.item.id;
            let eventObj;
            if (this.selectedTarget.classList.contains('e-appointment')) {
                eventObj = scheduleObj.getEventDetails(this.selectedTarget);
            }
            let selectedCells;
            let activeCellsData;
            switch (selectedMenuItem) {
                case 'Today':
                    scheduleObj.selectedDate = new Date();
                    break;
                case 'Add':
                case 'AddRecurrence':
                    selectedCells = scheduleObj.getSelectedElements();
                    activeCellsData = scheduleObj.getCellDetails(selectedCells.length > 0 ? selectedCells : this.selectedTarget);
                    if (selectedMenuItem === 'Add') {
                        scheduleObj.openEditor(activeCellsData, 'Add');
                    } else {
                        scheduleObj.openEditor(activeCellsData, 'Add', null, 1);
                    }
                    break;
                case 'Save':
                case 'EditOccurrence':
                case 'EditSeries':
                    if (selectedMenuItem === 'EditSeries') {
                        eventObj = new DataManager(scheduleObj.eventsData).
                        executeLocal(new Query().where(scheduleObj.eventFields.id, 'equal', eventObj[scheduleObj.eventFields.recurrenceID]))[0];
                    }
                    scheduleObj.openEditor(eventObj, selectedMenuItem);
                    break;
                case 'Delete':
                    scheduleObj.deleteEvent(eventObj);
                    break;
                case 'DeleteOccurrence':
                case 'DeleteSeries':
                    scheduleObj.deleteEvent(eventObj, selectedMenuItem);
                    break;
            }
        },
    }
};
</script>

<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-schedule/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";

.tailwind .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #f3f4f6;
}

.tailwind-dark .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #374151;
    color: #fff;
}

.material .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #fafafa;
}

.material-dark .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #2a2a2a;
    color: #ffff;
}

.fabric .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #ffffff;
}

.fabric-dark .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #333232;
    color: #fff;
}

.fluent .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #f3f2f1;
}

.fluent-dark .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #252423;
    border-color: #c1c1c1;
    color: #fff;
}

.bootstrap .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #f8f8f8;
}

.bootstrap-dark .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #2a2a2a;
    color: #fff;
}

.bootstrap5 .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #f8f9fa;
    color: #212529;
}

.bootstrap5-dark .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #343a40;
    color: #fff;
}

.highcontrast .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #000000;
    color: #ffffff;
}

.material3 .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #f3f1f8;
}

.material3-dark .schedule-overview .overview-content .right-panel .control-panel {
    background-color: #2a2731;
}

.schedule-overview .overview-toolbar.e-rtl + *{
    flex-direction: row-reverse;
}

.schedule-overview .overview-toolbar.e-toolbar {
    border-radius: 0;
}

.schedule-overview .overview-toolbar.e-toolbar .e-toolbar-item .e-tbar-btn.e-tbtn-txt {
    display: inline-grid;
}

.material .schedule-overview .overview-toolbar.e-toolbar {
    border-style: solid;
}

.schedule-overview .overview-toolbar.e-toolbar .e-toolbar-item:not(.e-separator) {
    padding: 3.5px;
}

.schedule-overview .overview-toolbar.e-toolbar .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon,
.schedule-overview .overview-toolbar.e-toolbar .e-toolbar-item .e-btn.e-tbar-btn .e-tbar-btn-text {
    line-height: 23px !important;
    min-height: 23px !important;
    padding: 0px !important;
}

.schedule-overview .overview-toolbar.e-toolbar .e-toolbar-item.e-template {
    margin: 0 5px;
}

.schedule-overview .overview-content {
    display: flex;
}

.schedule-overview .overview-content .left-panel {
    height: 700px;
    width: 100%;
}

.schedule-overview .overview-content .right-panel {
    display: block;
    height: 700px;
    transform: translateX(0%);
    transition: transform .5s ease, width 500ms;
    width: 500px;
}

.schedule-overview .overview-content .right-panel.hide {
    display: none;
    transform: translateX(100%);
    transition: transform .5s ease, width 500ms;
    width: 0;
}

.schedule-overview .overview-content .right-panel .control-panel {
    background: #f8f9fa;
    font-size: 14px;
    height: 100%;
    overflow: auto;
    padding: 15px;
    width: 100%;
}

.schedule-overview .overview-content .right-panel .control-panel .col-row {
    display: flex;
    flex-wrap: wrap;
}

.schedule-overview .overview-content .right-panel .control-panel label {
    font-weight: 500;
}

.schedule-overview .overview-content .right-panel .control-panel .col-left {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    margin-bottom: 1rem;
}

.schedule-overview .overview-content .right-panel .control-panel .col-right {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    margin-bottom: 1rem;
}

.schedule-overview .overview-scheduler {
    height: 100%;
    width: 100%;
}

.schedule-overview .e-schedule-toolbar .e-views,
.schedule-overview .e-schedule-toolbar .e-schedule-seperator,
.schedule-workweek.e-multi-select-list-wrapper.e-checkbox .e-filter-parent,
.schedule-resource.e-multi-select-list-wrapper.e-checkbox .e-filter-parent {
    display: none;
}

.schedule-overview .e-timeline-view .e-resource-left-td,
.schedule-overview .e-timeline-month-view .e-resource-left-td {
    width: 120px;
}

.schedule-resource.e-multi-select-list-wrapper.e-checkbox .e-list-parent:first-child li:first-child {
    background-image: none;
    opacity: .5;
    pointer-events: none;
    user-select: none;
}

.schedule-overview .calendar-import.e-upload {
    border: 0;
    padding-left: 0 !important;
}

.schedule-overview .calendar-import.e-upload .e-btn-icon {
    margin-left: initial;
}

.schedule-overview .calendar-import.e-upload .e-file-select-wrap {
    padding: 0
}

.schedule-overview .calendar-import.e-upload .e-file-select-wrap .e-file-drop,
.calendar-import .e-upload-files {
    display: none;
}

.e-bigger .schedule-context-menu ul .e-menu-item .e-menu-icon {
    font-size: 14px;
}

.schedule-context-menu ul .e-menu-item .e-menu-icon {
    font-size: 12px;
}

.schedule-overview.e-schedule .e-vertical-view .e-header-cells,
.schedule-overview.e-schedule .e-timeline-month-view .e-header-cells {
    padding: 0;
    text-align: center !important;
}

.schedule-overview.e-schedule .date-text {
    font-size: 14px;
}

.schedule-overview.e-schedule.e-device .date-text {
    font-size: 12px;
}

.schedule-overview.e-schedule .weather-image {
    width: 20px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.schedule-overview.e-schedule .e-month-view .weather-image {
    float: right;
    margin: -20px 2px 0 0;
    width: 20px;
    height: 20px;
}

.fluent .e-appbar .e-btn-icon.e-icons,
.fluent-dark .e-appbar .e-btn-icon.e-icons {
    margin-top: -2px;
}

.fluent .e-toolbar-item .e-checkbox-wrapper .e-icons.e-frame,
.fluent-dark .e-toolbar-item .e-checkbox-wrapper .e-icons.e-frame {
    height: 17px;
    line-height: 17px;
    width: 17px;
}

.fluent .e-toolbar-item .e-checkbox-wrapper .e-icons.e-frame.e-check,
.fluent-dark .e-toolbar-item .e-checkbox-wrapper .e-icons.e-frame.e-check {
    font-size: 15px;
    line-height: 16px;
}

.schedule-overview .overview-toolbar.e-toolbar .e-toolbar-item .e-tbar-btn {
    padding-top: 2px;
    padding-bottom: 2px;
}

#exportBtn {
    white-space: nowrap;
}

.time {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 0 5px;
}

.template {
    height: 46px;
    line-height: 23px;
}

.template .icon-child {
    text-align: center;
    height: 23px !important;
}

.template .text-child {
    font-size: 14px;
    line-height: 23px !important;
}

@media (max-width: 481px) {
    .schedule-overview .overview-content .right-panel {
        position: absolute;
        width: 90%;
    }

    .import-button,
    #printBtn,
    #exportBtn {
        display: none;
    }

}

@media (max-width: 1024px) {
    .schedule-overview .overview-content .right-panel .control-panel .col-row {
        display: initial;
    }

    .schedule-overview .overview-content .right-panel .control-panel .col-left {
        flex: initial;
        max-width: initial;
        margin-bottom: initial;
    }

    .schedule-overview .overview-content .right-panel .control-panel .col-right {
        flex: initial;
        max-width: initial;
    }
}
</style>
