<template>
    <div>
        <div style="background-color: white; padding:2%;">

            <div class="row editor">
                <div class="col-12 col-sm-12">
                    <form class="form" role="form" style="" @submit.prevent="">
                        <div class="row">

                            <div class="col-3 col-sm-3">

                                <div class="form-group">
                                    <label>IP Address</label>
                                    <input type="text" v-model="proxy.ipAddress" class="form-control required" required/>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Port</label>
                                    <input type="number" step="1" v-model="proxy.port" class="form-control required" required/>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Protocol</label>
                                    <select v-model="proxy.protocol" class="form-control required" required>
                                        <option value="">--None--</option>
                                        <option value="http">HTTP</option>
                                        <option value="https">HTTPS</option>
                                        <option value="socks">socks</option>
                                    </select>
                                </div>
                            </div>


                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Provider</label>
                                    <select v-model="proxy.provider" class="form-control required" required>
                                        <option value="">--None--</option>
                                        <option
                                            v-for="(provider, index) in providers"
                                            :value="provider.provider"
                                            :key="index"
                                        >
                                            {{ provider.provider }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6 col-sm-6">

                                <div class="form-group">
                                    <label>Username</label>
                                    <input type="text" v-model="proxy.username" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="text" v-model="proxy.password" class="form-control"/>
                                </div>
                            </div>
<!--                            <div class="col-3 col-sm-3">-->
<!--                                <div class="form-group">-->
<!--                                    <label>Nationwide</label>-->
<!--                                    <select v-model="item.nationwide" class="form-control required" required>-->
<!--                                        <option value="yes">Yes</option>-->
<!--                                        <option value="no">No</option>-->
<!--                                    </select>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>

                        <div class="row">
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Country</label>
                                    <select v-model="proxy.country" class="form-control text-capitalize">
                                        <option value="">-- None --</option>
                                        <option v-for="country in $root.countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>State</label>
                                    <select v-model="proxy.state" class="form-control text-capitalize">
                                        <option value="">-- None --</option>
                                        <option v-for="state in $root.states" :key="state._id" :value="state.code">{{state.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>City</label>
                                    <input type="text" v-model="proxy.city" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select v-model="proxy.status" class="form-control">
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                    </select>
                                </div>
                            </div>


                        </div>
<!--                        <div class="row">-->
<!--                            <div class="col-12 text-center">-->
<!--                                <b-button size="sm" variant="danger" @click="add()">-->
<!--                                   Add-->
<!--                                </b-button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        inboxEnhancedProxies-->

                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import moment from 'moment-timezone';


export default {
    name: 'inboxEnhancedProxies',
    // props:['user','item','proxies'],
    props: {
        item: String,
        user:Object,
        proxy: Object,
        onSave: {
            type: Function,
            required: true,
        },
    },
    components: {

    },
    data: function () {
        return {

            selected:[],
            mulSelect: false,
            selectedStatus: '',
            upload: {},
            result: {},
            items: {total:0, data:[]},
            countries:[],
            states:[],
            cities:[],
            providers:[],
            editor: false,
            filter: {name:'', status:'',provider:'',country:'',state:'',city:'',follow_proxy:'',nationwide:''},
            max: 25,
            page: 1,
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            },
            searching: false
        }
    },


    watch: {
        selected: function(value) {
            console.log(value);
            this.mulSelect = true;
        }
    },

    created(){
        this.loadcountries();
        this.loadstates();
        this.loadcities();
        this.getProviders();
        // window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    methods:{

        convert(time) {
            return moment.tz(time, this.user.timezone).format('MMMM Do YYYY, h:mm:ss a')
        },

        getProviders() {
            //Load the providers
            // this.$root.preloader = true;
            this.providers = [];

            axios.get(`${this.$root.serverUrl}/admin/proxies/providers`).then(function(resp){
                this.$root.preloader = false;
                this.providers = (resp && resp.data && resp.data.data) ? resp.data.data: [];
                this.$forceUpdate()
            }.bind(this));
        },

        test(proxy) {
            // this.$root.preloader = true;
            var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
            return axios.get(`${this.$root.serverUrl}/admin/proxies/test/${proxy._id}`, {params:request}).then((resp) => {
                this.result = resp.data.data;
                console.log(this.result)
                window.$('#result').modal('show')
                this.$root.preloader = false;
            }).catch((err) => {
                console.log(err)
                swal({title:"Notice", text: err.response.data.error, icon:"error"});
                this.$root.preloader = false;
            })
        },
        create: function(){
            this.item = {};
            this.editor = true;
        },

        save: function(){

            //Handle saving the changes
            var request = {'apikey': this.$root.apikey, id: this.item._id, args: this.item};
            // this.$root.preloader = true;

            axios({
                url: `${this.$root.serverUrl}/admin/proxies`,
                data: request,
                responseType: 'json',
                method: (this.item['_id']) ? 'POST' : 'PUT'
            }).then(function (resp) {
                //Process the results
                this.$root.preloader = false;
                if (resp.data && !resp.data.error) {
                    //Saved successfully, now update the parent
                    swal({
                        title: "Notice",
                        text: "Your changes were saved successfully",
                        icon: "success"
                    }).then(function (val) {
                        if (val) {
                            this.editor = false;
                            this.search();
                        }
                    }.bind(this));
                } else {
                    //Handle errors
                    this.errormsg = (resp.data && resp.data.error) ? resp.data.error : false;
                    this.$root.preloader = false;
                }
            }.bind(this)).catch(function (err) {
                this.$root.preloader = false;
                this.errormsg = err.message || "An unexpected error occured";
                swal({title: "Error", text: this.errormsg, icon: "error"});
            }.bind(this))
        },
        remove: function(obj){
            //Handle removing the item

            //if there are selected items, items will be deleted in bulk
            if(this.selected.length > 0) {

                swal({
                    title: "Remove",
                    text: "Are you sure",
                    icon: "info",
                    buttons: ['No', 'Yes']
                }).then(val => {

                    if (!val) return;

                    axios.delete(`${this.$root.serverUrl}/admin/proxies/delete/bulk`, {data: {list: this.selected}}).then(() => {
                        swal({
                            title: "Remove",
                            text: "Your request was completed successfully",
                            icon: "success"
                        }).then(function () {
                            this.selected = []
                            this.search();
                        }.bind(this));
                    })
                })



            } else {
                swal({
                    title: "Remove",
                    text: "Are you sure",
                    icon: "info",
                    buttons: ['No', 'Yes']
                }).then(function (val) {
                    if (!val) return;

                    var request = {'apikey': this.$root.apikey, id: obj._id};
                    // this.$root.preloader = true;

                    axios({
                        url: `${this.$root.serverUrl}/admin/proxies/${obj._id}`,
                        data: request,
                        responseType: 'json',
                        method: 'DELETE'
                    }).then(function (resp) {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            swal({
                                title: "Remove",
                                text: "Your request was completed successfully",
                                icon: "success"
                            }).then(function () {
                                this.search();
                            }.bind(this));
                        } else {
                            //Handle errors
                            swal({title: "Remove", text: "Unable to complete your request", icon: "error"});
                        }
                    }.bind(this))
                        .catch(function (err) {
                            this.$root.preloader = false;
                            this.errormsg = err.message || "An unexpected error occured";
                            swal({title: "Error", text: this.errormsg, icon: "error"});
                        }.bind(this))
                }.bind(this));
            }
        },
        edit: function(obj){
            //Edit the vendor item
            this.editor = true;
            // if(obj.nationwide == undefined) obj.nationwide = 'no'
            this.item = Object.clone({}, obj);
        },
        cancel: function(){
            this.editor = false;
            this.errormsg = false;
        },
        loadcountries: function(){
            var request = {'apikey': this.$root.apikey,sSearch:''};

            return axios.get(`${this.$root.serverUrl}/admin/countries`, {params:request}).then(function(resp){
                //Store the stats
                this.countries = (resp.data && resp.data.data)? resp.data.data : [];
                this.$forceUpdate()
            }.bind(this));
        },
        loadstates: function(){
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.filter.country) request.filters.country_iso_code = this.filter.country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                //Store the stats
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
                this.$forceUpdate()
            }.bind(this));
        },
        loadcities: function(){
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.filter.country) request.filters.country_iso_code = this.filter.country;
            if(this.filter.state) request.filters.subdivision_1_iso_code = this.filter.state;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                //Store the stats
                this.cities = (resp.data)? resp.data.data : [];
                this.$forceUpdate()
            }.bind(this));
        },

    }
}
</script>

<style scoped>

</style>



