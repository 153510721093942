<script>

import {ColumnDirective, ColumnsDirective, GridComponent as EjsGrid} from "@syncfusion/ej2-vue-grids";
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import Vue from "vue";
import swal from "sweetalert";
import axios from "axios";
import NewProxyService from "@/components/settings/proxy_providers/manage-proxy-services/new_proxy_service.vue";

let Bus = new Vue();
export default {
    name: 'ManageProxyServices',
    components: {
        NewProxyService,
        EjsGrid,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
        'ejs-dropdownlist': DropDownListComponent,
    },
    data() {
        return {
            selected_provider: '',
            selected_service: '',
            proxy_providers: [],
            proxy_dropdownFields: {text: 'provider_name', value: '_id'},
            proxy_services: [],
            services_dropdownFields: {text: 'service_name', value: '_id'},
            services: [],
        };
    },
    methods: {
        initialize() {
            this.getProviders();
            this.getProxyServices()
        },
        actionTemplate: function () {
            return {
                template: Vue.component("actionTemplate", {
                    template: `
                        <div class="d-flex" style="gap: 5px;">
                            <button
                                class="btn btn-sm btn-primary"
                            ><i class="fa fa-pencil" title="edit"></i></button>
                            <button class="btn btn-sm btn-danger"
                                    @click="remove"
                            ><i class="fa fa-trash" title="Delete"></i></button>
                        </div>
                    `,
                    data: function () {
                        return {
                            data: {},
                        };
                    },
                    computed: {
                        link() {
                            return `/scraper/facebook/result?config=${this.data._id}`;
                        },
                    },
                    methods: {
                        results() {
                            Bus.$emit("results", this.data);
                        },
                        details() {
                            Bus.$emit("edit", this.data);
                        },
                        remove: function () {
                            //Handle removing the item
                            swal({
                                title: "Remove",
                                text: "Are you sure?",
                                icon: "info",
                                buttons: ["No", "Yes"],
                            }).then((val) => {
                                if (!val) return;

                                var request = {
                                    apikey: this.$root.apikey,
                                    id: this.data._id,
                                };
                                // this.$root.preloader = true;

                                axios({
                                    url: `${this.$root.serverUrl}/admin/social/${this.data._id}`,
                                    data: request,
                                    responseType: "json",
                                    method: "DELETE",
                                })
                                    .then((resp) => {
                                        //Process the results
                                        this.$root.preloader = false;
                                        if (resp.data && !resp.data.error) {
                                            swal({
                                                title: "Removed",
                                                text: "Your request was completed successfully",
                                                icon: "success",
                                            }).then(
                                                function () {
                                                    Bus.$emit("delete");
                                                }.bind(this)
                                            );
                                        } else {
                                            //Handle errors
                                            swal({
                                                title: "Remove",
                                                text: resp.data.error
                                                    .message,
                                                icon: "error",
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        this.$root.preloader = false;
                                        this.errormsg =
                                            err.message ||
                                            "An unexpected error occurred";
                                        swal({
                                            title: "Error",
                                            text: this.errormsg,
                                            icon: "error",
                                        });
                                    });
                            });
                        },
                    },
                }),
            };
        },
        async getProviders() {
            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/proxy-providers`);
                this.proxy_providers = response.data.data;
                console.log(this.data);
            } catch (error) {
                console.log(error);
            }
        },
        onProviderChange(e) {
            this.selected_provider = e.value;
            this.getServiceByProvider();
        },
        onServiceChange(e) {
            this.selected_service = e.value;
        },
        onSearch() {
            axios.get(`${this.$root.serverUrl}/admin/proxy-services?provider=${this.selected_provider}&service=${this.selected_service}`).then(
                (resp) => {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.proxy_services = resp && resp.data && resp.data.data ? resp.data.data : [];
                }
            );
        },
        getServiceByProvider() {
            axios.get(`${this.$root.serverUrl}/admin/proxy-services/provider/${this.selected_provider}`).then(
                (resp) => {
                    console.log(resp.data.data)
                    this.$root.preloader = false;
                    this.services = resp && resp.data && resp.data.data ? resp.data.data : [];
                }
            );
        },
        async getProxyServices() {
            try {
                const response = await axios.get(`${this.$root.serverUrl}/admin/proxy-services`);
                this.proxy_services = response.data.data;
                console.log(this.data);
            } catch (error) {
                console.log(error);
            }
        },
    },
    mounted() {
        this.initialize();
    },
};
</script>

<template>
    <div class="container-fluid">
        <b-row class="mt-4 mb-2">
            <b-col cols="4">
                <ejs-dropdownlist placeholder="Provider" :dataSource="proxy_providers" cssClass="e-outline"
                                  :fields="proxy_dropdownFields"
                                  @change="onProviderChange"
                                  floatLabelType="auto"></ejs-dropdownlist>
            </b-col>
            <b-col cols="4">
                <ejs-dropdownlist placeholder="Service" :dataSource="services"
                                  :fields="services_dropdownFields"
                                  @change="onServiceChange"
                                  cssClass="e-outline"
                                  floatLabelType="auto"></ejs-dropdownlist>
            </b-col>
            <b-col cols="2" class="mr-auto">
                <b-button variant="primary" @click="onSearch">
                    <i class="fa fa-search"></i>
                    Search
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="d-flex justify-content-end mb-1">
                <new-proxy-service  :proxy_providers="proxy_providers" @close="initialize"/>
            </b-col>
        </b-row>
        <b-row>
            <ejs-grid :dataSource="proxy_services">
                <e-columns>
                    <e-column field="service_name" headerText="Service Name" width="120"></e-column>
                    <e-column field="provider_info.provider_name" headerText="Provider" width="120"></e-column>
                    <e-column field="display_price" headerText="Rate" width="100"></e-column>
                    <e-column field="display_start_date" headerText="Start Date" width="100"></e-column>
                    <e-column field="display_end_date" headerText="End Date" width="100"></e-column>
                    <e-column field="api_endpoint" headerText="API Endpoint" width="100"></e-column>
                    <e-column field="guid" headerText="GUID" width="100"></e-column>
                    <e-column field="auth_method" headerText="Auth Method" width="100"></e-column>
                    <e-column headerText="Actions" width="60" :template="actionTemplate"></e-column>
                </e-columns>
            </ejs-grid>

        </b-row>
    </div>
</template>

<style scoped>

</style>
