<template>
    <div class="col-12 mt4">
        <div class="container-fluid mb-5 mt-4">
            <div class="d-flex mt-4 mb-3 justify-content-end">
                <span>
                    <button class="btn btn-sm btn-info" @click="create = true;">
                        <i class="fa fa-assistive-listening-systems" ></i> New Device
                    </button>
                </span>
            </div>

            <div v-if="create" class="mb-5">
                <div class="col-12">
                    <div class="row mt-4">
                        <div class="col-3 pl-4 pr-4">
                            <label>Operating System<strong class="text-danger">*</strong></label>
                            <input
                                type="text"
                                v-model="device.os"
                                placeholder=""
                                class="form-control required"
                                required
                            />
                            <div class="form-text text-muted">Operating system name (eg. iOS)</div>
                        </div>
                        <div class="col-3 pl-4 pr-4">
                            <label>Versions <strong class="text-danger">*</strong></label>
                            <input-tag v-model="device.versions"></input-tag>
                            <div class="form-text text-muted">List of versions only to include.</div>
                        </div>
                        <div class="col-3 pl-4 pr-4">
                            <label>Mobile <strong class="text-danger">*</strong></label>
                            <select v-model="device.isMobile" class="form-control">
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <div class="form-text text-muted">Is this a mobile device?</div>
                        </div>
                        <div class="col-3 pl-4 pr-4 pt-4 mt-1">
                            <b-button
                                size="md"
                                variant="primary"
                                @click="add"
                                v-text="'Add'"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <ejs-grid ref="grid" :dataSource="item.devices" :allowPaging="true" :allowSorting="true" :pageSettings="pageSettings">
                    <e-columns>
                        <e-column field="os" headerText="Operating System" text-Align="Left"></e-column>
                        <e-column field="versions" headerText="Operating Version" text-Align="Left" ></e-column>
                        <e-column field="isMobile" headerText="Is Mobile" text-Align="Left" ></e-column>
                        <e-column :template="actionTemplate"></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import swal from "sweetalert";
import InputTag from 'vue-input-tag';
Vue.component('input-tag', InputTag)

Vue.prototype.$eventHub = new Vue();

export default {
    name: "devicesRule",
    props: ["item"],
    beforeDestroy() {
        this.$eventHub.$off('delete');
    },
    created() {
        this.$eventHub.$on('delete', (i)=>{
            this.$emit('removeDevices', i);
            this.$nextTick(() => {
                this.$forceUpdate();
            })
        });
    },
    computed: {},
    data: function () {
        return {
            device: {},
            create: false,
            pageSettings: {pageSize: 100},
            actionTemplate: function () {
                return {
                    template: Vue.component('tosTemplate', {
                            template: `<button @click="remove()" class='btn btn-primary btn-sm'>
                                          <span class="fa fa-trash" title="Remove"></span>
                                        </button>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            methods: {
                                remove: function(){
                                    swal({title:"Remove", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                                        if (!val) return;

                                        this.$eventHub.$emit('delete', parseInt(this.data.index));
                                    });
                                },
                            }
                        }
                    )
                }
            }
        };
    },
    methods: {
        add() {
            if (!this.device.os || !this.device.isMobile || !this.device.versions) {
                return swal({title: 'Error', text: "Please fill out all required fields", icon: 'error'})
            }

            this.$emit('addDevices', {created_at: new Date(), ...this.device});
            this.$nextTick(() => {
                this.$forceUpdate();
                this.device = {};
            })
        }
    }
};
</script>

<style>
</style>
