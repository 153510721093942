<script>
import Modal from "../utils/modal.vue";
export default {
    name: 'ViewProvider',
    components: {Modal,
    },
    props: {
        provider: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },

    },
    data() {
        return {
            fields: [
                {key: 'name', label: 'Name'},
                {key: 'email_id', label: 'Email ID'},
                {key: 'phone', label: 'Phone'}
            ]
        };
    },

    methods: {
        close() {
            this.$emit('close');
        }
    },

};

</script>

<template>
    <div>
        <Modal title="Provider" id="view_provider_modal" :visible.sync="visible">
            <b-row>
                <b-col cols="4">
                    <b-form-group
                        label="Provider Name"
                        label-for="provider_name"
                    >
                        <b-form-input
                            id="provider_name"
                            :value="provider.provider_name"
                            readonly
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="Address"
                        label-for="address"
                    >
                        <b-form-input
                            id="address"
                           :value="provider.address"
                            readonly
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group label="Phone Number" label-for="phone_number">
                        <b-form-input
                            id="phone_number"
                            :value="provider.phone"
                            readonly
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <h6 class="mt-4"><strong>Key Contacts</strong></h6>
            <b-row>
                <b-col cols="12" v-if="provider.key_contacts.length > 0">
                    <b-table :items="provider.key_contacts" :fields="fields" striped>
                    </b-table>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center align-items-center" v-else>
                    <strong>No key contacts added</strong>
                </b-col>
            </b-row>

            <h6 class="mt-4"><strong>Support Contracts</strong></h6>
            <b-row>
                <b-col cols="12" v-if="provider.support_contacts.length > 0">
                    <b-table :items="provider.support_contacts" :fields="fields" striped>
                    </b-table>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center align-items-center" v-else>
                    <strong>No support contacts added</strong>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="4">
                    <b-form-group
                        label="API Endpoint"
                        label-for="api_endpoint"
                    >
                        <b-form-input
                            id="api_endpoint"
                            :value="provider.api_endpoint"
                            readonly
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group
                        label="GUID"
                        label-for="guid"
                    >
                        <b-form-input
                            id="guid"
                            :value="provider.guid"
                            readonly
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group
                        label="Auth Method"
                        label-for="auth_method"
                    >
                        <b-form-input
                            id="auth_method"
                            :value="provider.auth_method"
                            readonly
                        ></b-form-input>

                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:footer>
                <b-button variant="secondary" @click="close">Close</b-button>
            </template>
        </Modal>
    </div>
</template>

<style scoped>

</style>
