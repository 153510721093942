<template>
    <div class="col-12">
        <div class="container-fluid mb-5 mt-4">
            <div class="d-flex mt-4 mb-3 justify-content-between">
                <h3>Social Configuration</h3>
            </div>

            <div class="card p-3 mb-3">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label>TWITTER API KEY</label>
                            <input
                                type="text"
                                v-model="item.social.tw_api_key"
                                class="form-control"
                                required
                            />
                            <div class="form-text text-muted">Api key for nimble social twitter endpoint</div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label>FACEBOOK API KEY</label>
                            <input
                                type="text"
                                v-model="item.social.fb_api_key"
                                class="form-control"
                                required
                            />
                            <div class="form-text text-muted">Api key for nimble social facebook endpoint</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "social",
    props: ["item"],
    components: {},
    computed: {},
    created() {
        if (!this.item.social) this.item.social = {}
    },
    data: function () {
        return {
            info: {},
            data: null,
        };
    },
    methods: {

    }
};
</script>

<style>
</style>
