<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 mb-3 ">

                <div class="control-styles">
                    <h6>Page Size</h6>
                    <ejs-dropdownlist  cssClass="e-outline" id='sample-list3' :dataSource='pageSizes' :mode='true' v-model="pageSettings.pageSize" placeholder='Page Size' ></ejs-dropdownlist>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-12 pt-3">
                <ejs-grid
                    ref="grid"
                    id="Grids"
                    :dataSource="data"
                    :toolbar='toolbar' :toolbarClick='toolbarClick' :excelExportComplete='exportComplete'
                    :pdfExportComplete='exportComplete' :allowFiltering="true" :allowExcelExport="true"
                    :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings"
                    :loadingIndicator='loadingIndicator'
                    :allowGrouping="true" :groupSettings='groupSettings'
                    :filterSettings="filterSettings" :csvExportComplete='exportComplete'
                >
                    <e-columns>


                        <e-column field='date_of_incident' headerText='Date Of Incident'

                                  :visible="visibleColumns.includes('date_of_incident')"
                        ></e-column>
                        <e-column field='date_last_update' headerText='Date Updated'

                                  :visible="visibleColumns.includes('date_last_update')"
                        ></e-column>
                        <e-column field='reasonForUpdate' headerText='Reason For Update'
                                  :visible="visibleColumns.includes('reasonForUpdate')"
                        ></e-column>
                        <e-column field='lastcounter' headerText='Previous Counter Value'
                                  :visible="visibleColumns.includes('lastcounter')"
                        ></e-column>
                        <e-column field='updatedBy' headerText='Updated By'
                                  :visible="visibleColumns.includes('updatedBy')"
                        ></e-column>

                    </e-columns>
                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <e-column type="Sum" field="lastcounter" format="N" :footerTemplate='footerSum'></e-column>
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>

                </ejs-grid>

                <ejs-pager :pageSize='pageSettings.pageSize' :pageCount='10'
                           :totalRecordsCount='pageSettings.totalRecordsCount'
                           @click="onPageClick"
                >
                </ejs-pager>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';

/*import VSelect from 'vue-select'*/
import { GridPlugin, Page, PdfExport, ExcelExport, Sort,Group, Aggregate, Filter,Toolbar,PagerPlugin} from '@syncfusion/ej2-vue-grids';
// import {DataManager, WebApiAdaptor} from "@syncfusion/ej2-data";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";
import axios from "axios";


Vue.use(GridPlugin);
Vue.use(PagerPlugin)
export default {
    name: "bounceRatetemplate",
    components: {},
    props: {
        user: {
            type: Object,
            required: false
        },
    },
    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter,PdfExport,Toolbar,Group ]
    },
    // props:['summary'],
    data: function() {
        return {
            loadingIndicator: { indicatorType: 'Shimmer' },
            columnOptions: [
                { id: "date_of_incident", name: "Date Of Incident" },
                { id: "date_last_update", name: "Date Updated" },
                { id: "reasonForUpdate", name: "Reason For Update" },
                { id: "lastcounter", name: "Last Counter" },
                { id: "updatedBy", name: "Updated By" },
                // Add more options for other columns
            ],
            selectedColumns:["date_of_incident","date_last_update",'reasonForUpdate','lastcounter','updatedBy'], // Set the initial selected columns
            visibleColumns: ["date_of_incident","date_last_update",'reasonForUpdate','lastcounter','updatedBy'],
            groupSettings:  {
                showDropArea: true,
                disablePageWiseAggregates: true,
            },
            counter: this.details,
            counterCheck: 0,
            counerCheckTwo:1,
            data: {},
            // data: new DataManager({
            //     url: `${this.$root.serverUrl}/admin/reports/incidentsReport`,
            //     adaptor: new WebApiAdaptor(),
            //     crossDomain: true,
            //     headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            // }),
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Total: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            repo:[],
            report:[],
            reports:[],
            proxieDataSet: [],
            filter:{
                to: '',
                from: '',
                status:'' ,
                provider:'',
                page:'',

            },
            toolbar: ['CsvExport','ExcelExport', 'PdfExport'],
            modalTitle:"Modal Title",
            modalShow:false,
            pageSizes:[10,25,50,75],
            pageSettings: {pageSize: 10},
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
        }
    },
    created() {
        this.loading()
        this.data.timezone = this.$store.state.user ? this.$store.state.user.timezone : null;
    },
    methods:{
        onPageClick(args) {
            // Update the current page when the user clicks on the pager
            this.filter.page = args.currentPage;
            this.pageSettings.currentPage = args.currentPage;
            this.loading();
        },
        exportComplete() {
            this.$refs.grid.hideSpinner();
        },
        viewDetails: function(){
            this.modalTitle = `Report Details (${this.data.name})`;
            this.modalShow = true;
        },
        customAggregateFn : function (data) {
            return data.result.filter((item) => item.error).length;
        },
        loading:function(){

            console.log('this is from the loading area')

              //fills the report array with the needed data
                // this.data = [];

                //this.$refs.griditem.showSpinner = true;


                const request = {
                    'apikey': this.$root.apikey,
                    filters: {
                        start: new Date(),
                        end: new Date()
                    }
                };


                request.pagesize = this.pageSettings.pageSize
                if (this.filter.page) request.filters.page = this.filter.page;

                axios.get(`${this.$root.serverUrl}/admin/reports/incidentsReport`, {params: request}).then(function (resp) {
                    //Store the stats
                    let count = (resp.data) ? resp.data.Count : 0;
                    let data = (resp.data) ? resp.data.Items : [];
                    if (resp.data.error) {
                        swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                    } else {
                        this.data = data
                        // {result:data, count:count}
                        this.pageSettings.totalRecordsCount = count;
                        this.pageSettings.pageCount = Math.ceil(count / this.pageSettings.pageSize);

                    }
                    this.$refs.griditem.hideSpinner = true;
                }.bind(this)).catch(() => {
                    this.reportLoading = false;
                });


        },
        toolbarClick: function(args = ClickEventArgs) {
            this.$refs.grid.showSpinner();
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
                case 'CSV Export':
                    (this.$refs.grid).csvExport(this.getCsvExportProperties());
                    break;
            }
        },
        getCsvExportProperties: function() {
            return {
                fileName: "exported_data.csv"
            };
        },
        getExcelExportProperties: function() {
            return {
                fileName: "exported_data.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: "exported_data.pdf"
            };
        },

    },
}
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';

@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
</style>
